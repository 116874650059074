<template>
    <div class="container" style="max-width: 900px;">
        <p class="page-subtitle" style="margin-bottom: 48px">My Consumption</p>

        <div :class="{'loading': loading.data}">
            <div class="consumption-card" v-for="consumption in consumptions">
                <div class="consumption-card__header">
                    {{ consumption.utility }}
                </div>
                <div class="consumption-card__body">
                    <div style="font-size: 16px; padding-left: 16px;">{{ consumption.unitOfMeasure }}</div>
                    <line-chart :series="consumption.series" :categories="consumption.series[1].categories" :units="consumption.unitOfMeasure"/>
                </div>
            </div>

            <div v-if="!loading.data && consumptions.length === 0" class="text-center text-muted">No data</div>
            <div class="text-center" v-else-if="!loading.data">
                <json-c-s-v :data="data" name="MyConsumption.csv"
                            :fields="['SupplyType', 'Month', 'Consumption', 'unitOfMeasure']"
                            :labels="{
                                SupplyType: 'Supply Type',
                                Month: 'Month',
                                Consumption: 'Consumption',
                                unitOfMeasure: 'Unit of Measure'
                            }"
                >
                    <a class="btn btn-outline-primary">Download list</a>
                </json-c-s-v>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import chunk from 'lodash/chunk'
import orderBy from 'lodash/orderBy'
import LineChart from '../components/Charts/LineChart'
import moment from 'moment'
import JsonCSV from 'vue-json-csv'

export default {
    name: 'MyConsumption',
    components: { LineChart, JsonCSV },
    data () {
        return {
            loading: {
                data: false
            },
            filters: {},
            consumptions: [],
            data: [],
        }
    },
    computed: {
        ...mapState({
            selected_property: (state) => state.selected_property,
        }),
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.loading.data = true
            let filters = this.filters
            filters.propertyid = this.selected_property.billingPropertyID;
            filters.stid = this.selected_property.billingTenantID;
            filters.lhid = this.selected_property.billingOwnerID;

            this.$http.get('/getconsumption', { params: filters })
                .then((res) => {
                    this.loading.data = false
                    let data = [];
                    for (let i = 0; i < res.body.supplyTypeList.length; i++) {
                        const supplyType = res.body.supplyTypeList[i]['supplyType'];
                        if (!supplyType) {
                            continue;
                        }
                        res.body.supplyTypeList[i]['consumptionList'].forEach((item) => {
                            data.push({
                                SupplyType: supplyType,
                                Month: item['monthText'],
                                Consumption: String(item['consumption']).replace(',', ''),
                                unitOfMeasure: res.body.supplyTypeList[i]['unitOfMeasure'],
                                monthSortOrder: item['monthSortOrder'],
                            })
                        })

                        let consumptionList = chunk(res.body.supplyTypeList[i]['consumptionList'].map((item) => {
                           return {
                               label: item['monthText'],
                               x: moment(item['month'], 'M').format('MMM'),
                               y: String(item['consumption']).replace(',', ''),
                           }
                        }), 12);

                        let categories = chunk(res.body.supplyTypeList[i]['consumptionList'].map((item) => {
                            return item['monthText']
                        }), 12);

                        consumptionList = [
                            {
                                name: 'Previous year',
                                categories: categories[0],
                                data: consumptionList[0]
                            },
                            {
                                name: 'Current year',
                                categories: categories[1],
                                data: consumptionList[1]
                            }
                        ]
                        this.consumptions.push({
                            unitOfMeasure: res.body.supplyTypeList[i]['unitOfMeasure'],
                            utility: supplyType,
                            series: consumptionList
                        });
                    }

                    data = orderBy(data, ['SupplyType', 'monthSortOrder'], ['asc', 'desc'])
                    console.log(data)
                    this.data = data
                })
                .catch((error) => {
                    console.log(error)
                    this.loading.data = false
                })
        }
    }
}
</script>
