<template>
    <div class="container" style="max-width: 900px;">
<!--        <h1 class="page-title">My statements</h1>-->
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Thank you for your payment.</p>

        <p class="text-muted">Barclaycard will send you an email to confirm your payment.</p>

        <p class="text-muted">The Ginger Billing Team</p>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'PayByCard',
    components: { PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: true,
            },
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
}
</script>
