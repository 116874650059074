<template>
    <div class="container" style="max-width: 900px;">
        <h1 class="page-title">Messages</h1>

        <property-details class="mb-3"/>
        <template v-if="selected_property">
            <template v-if="selected_property.infoList.length > 0">
                <div class="message" v-for="message in selected_property.infoList">
                    <!--                <div class="message__header">-->
                    <!--                    {{ question.question }}-->
                    <!--                </div>-->
                    <div class="message__body">
                        {{ message.infoMessage }}
                    </div>
                </div>
            </template>
            <div class="empty-text" v-else>
                No messages
            </div>
        </template>
        <div class="empty-text" v-else>
            Please select a property
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'Messages',
    components: { PropertyDetails },
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            selected_property: (state) => state.selected_property,
        }),
    },
}
</script>
