<template>
    <div class="balance-transaction">
        <div class="balance-transaction__header">
            <span>{{ transaction.transactionDate | moment("DD MMMM YYYY", 'DD/MM/YYYY HH:mm:ss') }}</span>
            <span>{{ transaction.accountName }} ({{ transaction.allocateToAbbreviated }})</span>
        </div>
        <div class="balance-transaction__body">
            <a href="#" v-if="['Opening balance', 'Take on balance', 'Adjustment'].indexOf(transaction.transactionType) === -1" @click.prevent="expanded = !expanded" class="btn" :class="{'rotate': expanded}"><i class="flaticon-chevron"></i></a>
            <a v-else style="width: 25px"></a>
            <div class="balance-transaction__info">
                <template v-if="transaction.transactionType === 'Payment'">
                    <div class="balance-transaction__title" style="margin-top: 10px">
                        Payment for invoice no. {{ transaction.invoiceNumber }} <a href="#" :class="{'loading': this.loading.pdf}" @click.prevent="downloadPDF"> <i class="flaticon-bills"></i></a>
                    </div>
                    <div class="balance-transaction__description">For <b>{{ transaction.supplyType }}</b></div>
                    <div class="balance-transaction__description text-muted" v-show-slide="expanded">
                        You paid <b>{{ -1 * $options.filters.parsePrice(transaction.originalTransactionValue) | toPrice }}</b><br>
                        <b>{{ ($options.filters.parsePrice(transaction.transactionValue) < 0 ? $options.filters.parsePrice(transaction.transactionValue) * -1 : transaction.transactionValue) | toPrice }}</b> was allocated to invoice no. <b>{{ transaction.invoiceNumber }}</b>
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Take on balance'">
                    <div class="balance-transaction__title" style="margin-top: 10px">
                        Take on balance
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Adjustment'">
                    <div class="balance-transaction__title" style="margin-top: 10px">
                        Adjustment
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Invoice'">
                    <div class="balance-transaction__title" style="margin-top: 10px">
                        Invoice no. {{ transaction.invoiceNumber }} <a href="#" :class="{'loading': this.loading.pdf}" @click.prevent="downloadPDF"> <i class="flaticon-bills"></i></a>
                    </div>
                    <div class="balance-transaction__description">For <b>{{ transaction.supplyType }}</b></div>
                    <div class="balance-transaction__description text-muted" v-show-slide="expanded">
                        {{ transaction.accountName }}<br>
                        {{ transaction.billingPeriod }}<br>
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Payment on account'">
                    <div class="balance-transaction__title" style="margin-top: 20px">
                        Payment on account
                    </div>
                    <div class="balance-transaction__description text-muted" v-show-slide="expanded">
                        {{ transaction.accountName }}<br>
                        You paid {{ -1 * $options.filters.parsePrice(transaction.originalTransactionValue) | toPrice }}<br>
                        {{ transaction.transactionValue | toPrice }} has not been allocated yet<br>
                        {{ transaction.billingPeriod }}<br>
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Credit note'">
                    <div class="balance-transaction__title" style="margin-top: 10px">
                        Credit note no. {{ transaction.invoiceNumber }} <a href="#" :class="{'loading': this.loading.pdf}" @click.prevent="downloadPDF"> <i class="flaticon-bills"></i></a>
                    </div>
                    <div class="balance-transaction__description">For <b>{{ transaction.supplyType }}</b></div>
                    <div class="balance-transaction__description text-muted" v-show-slide="expanded">
                        {{ transaction.accountName }}<br>
                        {{ transaction.billingPeriod }}<br>
                    </div>
                </template>
                <template v-if="transaction.transactionType === 'Opening balance'">
                    <div class="balance-transaction__title" style="margin-top: 20px">
                        Opening balance
                    </div>
                </template>
            </div>
            <div class="balance-transaction__amount">
                {{ transaction.transactionValue | toPrice }}<br>
                <div class="balance-transaction__closing_balance">
                    Closing balance: {{ transaction.closingBalance | toPrice }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'TransactionItem',
    props: ['transaction', 'property'],
    data () {
        return {
            loading: {
                pdf: false,
            },
            expanded: false,
        }
    },
    methods: {
        downloadPDF() {
            if (this.loading.pdf) {
                return false
            }
            this.loading.pdf = true
            this.$http.get('/getinvoice', { params: {
                invoiceid: this.transaction.invoiceID,
                propertyid: this.property.billingPropertyID,
                stid: this.property.billingTenantID,
                lhid: this.property.billingOwnerID,
            }})
                .then(function (res) {
                    this.loading.pdf = false
                    if(!res.body.errorNumber) {
                        var a = document.createElement("a");
                        a.href = "data:application/pdf;base64," + (res.body.fileContents)
                        a.download = res.body.fileName;
                        a.click();
                        a.remove()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>
