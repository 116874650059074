<template functional>
    <div class="form-group" v-bind:class="[data.staticClass, data.class, {'error' : props.v ? props.v.$error : null}]">
        <label v-if="props.label" :for="props.for">{{ props.label }}</label>
        <slot></slot>
        <p v-if="props.help"><small class="text-muted">{{ props.help }}</small></p>
    </div>
</template>

<script>
export default {
  name: 'FormGroup',
};
</script>
