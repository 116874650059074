<template>
    <div class="container" style="max-width: 900px;">
        <p class="page-subtitle" style="margin-bottom: 48px">Payment info</p>

        <div v-html="selected_property.paymentText"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'PaymentInfo',
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            selected_property: (state) => state.selected_property,
        }),
    },
    mounted () {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }
}
</script>
