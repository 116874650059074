<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
                    <router-link :to="{name: 'account'}" class="ml-md-5">
                        <img alt="image-404" src="/images/logo_header.png" class="theme-logo">
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container-fluid error-content">
            <div class="">
                <h1 class="error-number">404</h1>
                <p class="mini-text">Ooops!</p>
                <p class="error-text mb-4 mt-1">The page you requested was not found!</p>
                <router-link tag="a" :to="{name: 'account'}" class="btn btn-primary mt-5">Go Back</router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Dashboard',
    data () {
        return {
            loading: false,
            clients: {
                total: 10,
            },
        }
    },
    mounted () {
        $('body').addClass('error404 text-center')
        this.$router.push({ name: 'clients.list' })
    },
    methods: {
        getData () {
            this.loading = true
        },
    },
    created () {
        this.getData()
    },
}
</script>
