<template>
    <div class="box my-2" v-if="show_site">
        <div class="box__body">
            <div><b>Site Name </b>{{ show_site.siteName }}</div>
            <div><b>Site ID </b>[{{ show_site.billingSiteID }}]</div>
            <div v-if="with_balance"><b>Site Balance </b> ???</div>
            <div v-if="$route.name !== 'site-select'" class="mt-2"><router-link class="btn btn-sm btn-outline-primary" :to="{name: 'site-select'}" style="font-size: 16px; padding: 4px 8px;">Change site</router-link></div>

<!--            <div v-if="show_site.addressLine1">{{ show_site.addressLine1 }}</div>-->
<!--            <div v-if="show_site.addressLine2">{{ show_site.addressLine2 }}</div>-->
<!--            <div v-if="show_site.addressLine3">{{ show_site.addressLine3 }}</div>-->
<!--            <div v-if="show_site.addressLine4">{{ show_site.addressLine4 }}</div>-->
<!--            <div v-if="show_site.addressLine5">{{ show_site.addressLine5 }}</div>-->
<!--            <div v-if="show_site.addressPostcode">{{ show_site.addressPostcode }}</div>-->
<!--            <br>-->
<!--            <div><b>Prime Authority:</b> [xxxx]</div>-->
<!--            <div><b>Block Managing Agent:</b> [xxxxs]</div>-->
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
    name: 'SiteDetails',
    props: ['site', 'with_balance'],
    computed: {
        ...mapState({
            selected_site: (state) => state.selected_site,
        }),
        show_site() {
            return this.site ? this.site : this.selected_site;
        },
        hasMoreSites() {
            return this.$store.getters.hasMoreSites
        }
    }
}
</script>
