<template>
    <div class="container page" style="max-width: 900px;">
<!--        <h1 class="page-title">My account</h1>-->
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Welcome to your account for the property</p>
<!--        <div class="balance-box">-->
<!--            <div class="balance-box__header">-->
<!--                <div class="balance-box__text">Your account balance is</div>-->
<!--                <div class="balance-box__amount">$155.70</div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <property-select/>-->
        <property-details/>

        <div :class="{'loading-big': loading.data}"></div>
        <template v-if="selected_property">
            <div class="service-grid">
                <router-link :to="{name: 'transactions'}" class="service-grid__item" v-if="selected_property.allowTransactions">
                    <div class="service-grid__item-icon"><i class="flaticon-balance"></i></div>
                    <div class="service-grid__item-title">My Transactions</div>
                </router-link>
                <router-link :to="{name: 'statements'}" class="service-grid__item" v-if="selected_property.allowStatements">
                    <div class="service-grid__item-icon"><i class="flaticon-bills"></i></div>
                    <div class="service-grid__item-title">My Statements</div>
                </router-link>
<!--                <div class="service-grid__item">-->
<!--                    <div class="service-grid__item-icon"><i class="flaticon-settings"></i></div>-->
<!--                    <div class="service-grid__item-title">Account settings</div>-->
<!--                </div>-->
                <router-link :to="{name: 'submit-readings'}" class="service-grid__item" v-if="selected_property.allowReads">
                    <div class="service-grid__item-icon"><i class="flaticon-meter"></i></div>
                    <div class="service-grid__item-title">Submit meter reads</div>
                </router-link>
                <router-link :to="{name: selected_property.paymentMethod === 'gateway' ? 'make-payment' : 'payment-info'}" class="service-grid__item" v-if="selected_property.allowPayment">
                    <div class="service-grid__item-icon"><i class="flaticon-credit-card"></i></div>
                    <div class="service-grid__item-title">Make a payment</div>
                </router-link>
                <router-link :to="{name: 'moving'}" class="service-grid__item" v-if="selected_property.allowMove && ['Leaseholder', 'Sub tenant', 'Managing agent', 'Letting agent', 'Prime authoritie'].indexOf(selected_property.portalUserRole) > -1">
                    <div class="service-grid__item-icon"><i class="flaticon-moving-truck"></i></div>
                    <div class="service-grid__item-title">{{['Leaseholder', 'Managing agent', 'Letting agent', 'Prime authoritie'].indexOf(selected_property.portalUserRole) > -1 ? 'Change of occupancy' : "I'm moving" }}</div>
                </router-link>

<!--                <router-link :to="{name: 'gas'}" class="service-grid__item" v-if="selected_property.has_gas || true">-->
<!--                    <div class="service-grid__item-icon"><i class="flaticon-flame"></i></div>-->
<!--                    <div class="service-grid__item-title">My Gas Invoices</div>-->
<!--                </router-link>-->
<!--                <router-link :to="{name: 'electricity'}" class="service-grid__item" v-if="selected_property.has_electricity || true">-->
<!--                    <div class="service-grid__item-icon"><i class="flaticon-light"></i></div>-->
<!--                    <div class="service-grid__item-title">My Electricty Invoices</div>-->
<!--                </router-link>-->
<!--                <router-link :to="{name: 'heat'}" class="service-grid__item" v-if="selected_property.has_heat || true">-->
<!--                    <div class="service-grid__item-icon"><i class="flaticon-light"></i></div>-->
<!--                    <div class="service-grid__item-title">My Heat Network Invoices</div>-->
<!--                </router-link>-->
<!--                <router-link :to="{name: 'water'}" class="service-grid__item" v-if="selected_property.has_water || true">-->
<!--                    <div class="service-grid__item-icon"><i class="flaticon-water"></i></div>-->
<!--                    <div class="service-grid__item-title">My Water Invoices</div>-->
<!--                </router-link>-->
                <router-link :to="{name: 'my-consumption'}" class="service-grid__item" v-if="selected_property.allowConsumptionProfile">
                    <div class="service-grid__item-icon"><i class="flaticon-pie-chart"></i></div>
                    <div class="service-grid__item-title">My Consumption</div>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'Dashboard',
    components: { PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    mounted () {
        if (!this.selected_property) {
            this.$router.push({name: 'property-select'})
        }
    }
}
</script>
