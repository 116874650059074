<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass]">
        <div class="input-group">
            <div class="input-group-prepend" v-if="!!$slots.prepend">
                <span class="input-group-text"><slot name="prepend"></slot></span>
            </div>
            <input :type="props.type ? props.type: 'text'" class="form-control" :placeholder="props.placeholder"
                   :class="{
                        'form-control-lg': props.size === 'lg',
                        'form-control-sm': props.size === 'sm',
                        'form-control-xs': props.size === 'xs',
                        'is-invalid' : props.v ? props.v.$error : null
                    }"
                   :value="props.value"
                   :disabled="props.disabled"
                   :readonly="props.readonly"
                   :required="props.required"
                   :step="props.step"
                   :name="props.name"
                   v-on:input="($event) => {
                       if(listeners.input){
                           let value = $event.target.value;
                           // if(props.decimals !== undefined){
                           //      value = value.match(/^\d+\.?\d{0,0}/)
                           // }
                           listeners.input(value);
                       }
                       // listeners.input && listeners.input($event.target.value);
                       if(props.v){
                           props.v.$touch();
                       }
                   }"
                   v-on:keyup="listeners.keyup && listeners.keyup($event)"
                   v-on:keypress="listeners.keypress && listeners.keypress($event)"
                   v-on:keydown="listeners.keydown && listeners.keydown($event)"
            >
            <div class="input-group-prepend" v-if="!!$slots.append">
                <span class="input-group-text"><slot name="append"></slot></span>
            </div>
        </div>
    </form-group>
</template>

<script>
export default {
    name: 'FormInput',
}
</script>
