<template>
    <div class="container page" style="max-width: 900px;" :class="{'loading-btn': loading.data}">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Select the property you want to manage</p>
        <template v-if="selected_site">
            <site-details/>
            <hr>
        </template>

        <form-input v-if="properties.length > 10" class="mb-3" type="search" v-model="term" placeholder="Search"/>

        <a href="#" class="mb-2" v-for="property in filteredProperties" @click.prevent="selectProperty(property)">
            <property-details :property="property"/>
        </a>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'
import SiteDetails from '../components/SiteDetails'

export default {
    name: 'PropertySelect',
    components: { SiteDetails, PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: false
            },
            term: ''
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            sites: (state) => state.sites,
            properties: (state) => state.properties,
            selected_site: (state) => state.selected_site,
        }),
        filteredProperties () {
            if (this.term.trim() === '') {
                return this.properties
            }
            let lowerTerm = this.term.toLowerCase()

            return this.properties.filter((property) => {
                return property.siteName.toLowerCase().indexOf(lowerTerm) > -1 || property.addressLine1.toLowerCase().indexOf(lowerTerm) > -1 || String(property.billingPropertyID).toLowerCase().indexOf(lowerTerm) > -1;
            })
        },
    },
    created () {
        if (this.properties.length === 0) {
            this.getProperties()
        }
    },
    methods: {
        selectProperty (property) {
            this.$store.commit('selectProperty', property)
            this.$router.push({ name: 'dashboard' });
        },
        getProperties () {
            this.loading.data = true;
            this.$http.get('/userproperties', {
                params: {siteId: this.selected_site ? this.selected_site.billingSiteID : null}
            })
                .then((res) => {
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error loading your properties. Please contact Ginger support.')
                        this.$store.commit('logout')
                        this.$auth.destroySession();
                        this.$acl.change('public');
                        return
                    }

                    this.$store.commit('setProperties', res.body.propertyList)
                    this.loading.data = false

                    if(res.body.propertyList.length === 1){
                        this.$store.commit('selectProperty', res.body.propertyList[0])
                        this.$router.push({ name: 'dashboard' })
                    }
                })
                .catch((e) => {
                    console.log(e)
                    this.loading.data = false
                })
        },
    }
}
</script>
