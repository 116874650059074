<template>
    <div class="question">
        <div class="question__header" @click="expanded = !expanded">
            {{ question.question }} <span class="question__chevron" :class="{'rotate': expanded}"><i class="flaticon-chevron"></i></span>
        </div>
        <div v-show-slide="expanded">
            <div class="question__body">
                {{ question.answer }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FAQItem',
    props: ['question'],
    data () {
        return {
            expanded: false,
        }
    },
}
</script>
