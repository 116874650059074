var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",staticStyle:{"max-width":"900px"}},[_c('div',{staticClass:"page-subtitle"},[_vm._v("Hi "+_vm._s(_vm.user.name))]),_c('p',{staticClass:"page-description"},[_vm._v("Here are your transactions")]),_c('property-details',{staticClass:"mb-3"}),_c('div',{staticClass:"inline-form mb-4 align-items-end"},[_c('form-date',{attrs:{"size":'sm',"time":false,"altFormat":"d.m.Y","dateFormat":"Y-m-d","label":"From date"},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}}),_c('form-date',{attrs:{"size":'sm',"time":false,"altFormat":"d.m.Y","dateFormat":"Y-m-d","label":"To date"},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}}),_c('form-btn',{staticClass:"btn btn-outline-secondary",attrs:{"size":'sm',"href":"#"},on:{"click":_vm.clearFilters}},[_vm._v("Clear")])],1),(_vm.transactions)?_c('div',{class:{'loading': _vm.loading.data},staticStyle:{"min-height":"80px"}},_vm._l((_vm.transactions),function(transaction){return _c('div',[_c('transaction-item',{attrs:{"transaction":transaction,"property":_vm.selected_property}})],1)}),0):_vm._e(),_c('div',{staticClass:"text-center mb-4"},[(_vm.loading.load_more || _vm.pagination.actualPageNumber < _vm.pagination.numberOfPages)?_c('a',{staticClass:"btn btn-sm btn-secondary",class:{'loading': _vm.loading.load_more},on:{"click":function($event){$event.preventDefault();return _vm.loadMore($event)}}},[_vm._v("Load more")]):_vm._e()]),_c('hr'),_c('div',{staticClass:"text-center mb-4"},[_c('json-c-s-v',{attrs:{"data":_vm.getData(),"name":"Transactions.csv","fields":['transactionDate', 'transactionType', 'invoiceNumber', 'accountName', 'supplyType', 'billingPeriod', 'originalTransactionValue', 'transactionValue', 'closingBalance', 'allocateToAbbreviated'],"labels":{
                            transactionDate: 'Transaction date',
                            transactionType: 'Transaction type',
                            invoiceNumber: 'Invoice number',
                            accountName: 'Account name',
                            supplyType: 'Supply type',
                            billingPeriod: 'Billing period',
                            originalTransactionValue: 'Payment you made',
                            transactionValue: 'Amount',
                            closingBalance: 'Closing balance',
                            allocateToAbbreviated: 'AllocateToAbbreviated',
                        }}},[_c('a',{staticClass:"btn btn-outline-primary"},[_vm._v("Download transactions list")])])],1)],1)}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }