<template>
    <div class="box my-2" v-if="show_property">
        <div class="box__body">
            <div>{{ show_property.siteName }}</div>
            <div><b>Property Name </b>{{ show_property.addressLine1 }}</div>
            <div><b>Property ID </b>[{{ show_property.billingPropertyID }}]</div>
            <div v-if="$route.name === 'property-select' && show_property.portalUserRole === 'Letting agent'"><b>Leaseholder </b> {{ show_property.title }} {{ show_property.forenames }} {{ show_property.surname }}</div>
<!--            <div v-if="with_balance"><b>Property Balance </b> ???</div>-->
            <div v-if="$route.name !== 'property-select' && $route.name !== 'pay-by-card'" class="mt-2">
                <router-link class="btn btn-sm btn-outline-primary" :to="{name: 'property-select'}" style="font-size: 16px; padding: 4px 8px;">Change property</router-link>
            </div>

<!--            <div v-if="show_property.addressLine1">{{ show_property.addressLine1 }}</div>-->
<!--            <div v-if="show_property.addressLine2">{{ show_property.addressLine2 }}</div>-->
<!--            <div v-if="show_property.addressLine3">{{ show_property.addressLine3 }}</div>-->
<!--            <div v-if="show_property.addressLine4">{{ show_property.addressLine4 }}</div>-->
<!--            <div v-if="show_property.addressLine5">{{ show_property.addressLine5 }}</div>-->
<!--            <div v-if="show_property.addressPostcode">{{ show_property.addressPostcode }}</div>-->
<!--            <br>-->
<!--            <div><b>Prime Authority:</b> [xxxx]</div>-->
<!--            <div><b>Block Managing Agent:</b> [xxxxs]</div>-->
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
    name: 'PropertyDetails',
    props: ['property', 'with_balance'],
    computed: {
        ...mapState({
            selected_property: (state) => state.selected_property,
        }),
        show_property() {
            return this.property ? this.property : this.selected_property;
        },
        hasMoreProperties() {
            return this.$store.getters.hasMoreProperties
        }
    }
}
</script>
