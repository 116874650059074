<template>
    <div class="balance-statement">
        <div class="balance-statement__header">
            {{ statement.statementDate | moment("DD MMMM YYYY", 'DD/MM/YYYY HH:mm:ss') }}
        </div>
        <div class="balance-statement__body">
            <a href="#" v-if="statement.statementType !== 'Opening balance'" @click.prevent="expanded = !expanded" class="btn" :class="{'rotate': expanded}"><i class="flaticon-chevron"></i></a>
            <a v-else style="width: 25px"></a>
            <div class="balance-statement__info">
                <div class="balance-statement__title" style="margin-top: 20px">
                    Statement no. {{ statement.statementID }} <a href="#" :class="{'loading': this.loading.pdf}" @click.prevent="downloadPDF"> <i class="flaticon-bills"></i></a>
                </div>
                <div class="balance-statement__description text-muted" v-show-slide="expanded">
                    {{ statement.accountName }}<br>
                    {{ statement.startDate | moment('DD/MM/YYYY', 'DD/MM/YYYY') }} - {{ statement.endDate | moment('DD/MM/YYYY', 'DD/MM/YYYY') }}
                </div>
            </div>
            <div class="balance-statement__amount">
                {{ statement.closingBalance | toPrice }}<br>
                <div class="balance-statement__closing_balance">
                    Closing balance
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'StatementItem',
    props: ['statement', 'property'],
    data () {
        return {
            loading: {
                pdf: false,
            },
            expanded: false,
        }
    },
    methods: {
        downloadPDF() {
            if (this.loading.pdf) {
                return false
            }
            this.loading.pdf = true
            this.$http.get('/getstatement', { params: {
                statementid: this.statement.statementID,
                propertyid: this.property.billingPropertyID,
                stid: this.property.billingTenantID,
                lhid: this.property.billingOwnerID,
            }})
                .then(function (res) {
                    this.loading.pdf = false
                    if(!res.body.errorNumber) {
                        var a = document.createElement("a");
                        a.href = "data:application/pdf;base64," + (res.body.fileContents)
                        a.download = res.body.fileName;
                        a.click();
                        a.remove()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>
