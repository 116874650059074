<template>
    <div class="container page" style="max-width: 700px;">
        <h1 class="page-title">Register</h1>


        <div class="page-description" v-if="success === true">
            You will shortly receive an email to the address you have provided to confirm the registration – please action this as soon as possible.<br><br>

            Once we have confirmed the information (which usually takes no longer than 24 hours), we will send you an email to complete the portal registration.<br><br>

            Thank you for taking the time to register!<br><br>

            <div class="text-center mt-2">
                <router-link :to="{name: 'login'}" class="btn btn-outline-secondary --w350">Back to login</router-link>
            </div>
        </div>

        <form @submit.prevent="submitRegister" v-else>
            <form-select label="Please tell us your interest in the property:"
                         v-model="register.RequestType"
                         placeholder="Select"
                         class="mb-4"
                :options="[
                    {label: 'I am a Leaseholder', value: 'Leaseholder'},
                    {label: 'I am a Leaseholder and have a new Sub-tenant', value: 'Tenant'},
                    {label: 'I am a Sub-tenant moving in to a property', value: 'SubTenant'},
                    {label: 'I am a Letting Agent advising of a new Sub-tenant', value: 'Letting'},
                ]"
            />

            <template v-if="register.RequestType">
                <template v-if="register.RequestType === 'Leaseholder'">
                    <div class="text-muted" v-if="register.RequestType === 'Leaseholder'">Please provide the following information about you and your new leasehold:</div>

                    <form-input class="mb-3" v-model="register.RequestTitle" :v="$v.register.RequestTitle" label="Title"/>
                    <form-input class="mb-3" v-model="register.RequestForenames" :v="$v.register.RequestForenames" label="First name"/>
                    <form-input class="mb-3" v-model="register.RequestSurname" :v="$v.register.RequestSurname" label="Last name"/>
                    <form-input class="mb-3" type="email" v-model="register.RequestEmail" :v="$v.register.RequestEmail" label="Email address"/>
                    <form-input class="mb-3" type="tel" v-model="register.RequestPhone" :v="$v.register.RequestPhone" label="Phone number"/>
                    <form-input class="mb-3" v-model="register.RequestSTAddressLine1" :v="$v.register.RequestSTAddressLine1" label="Property address"/>
                    <form-input class="mb-3" v-model="register.RequestSTPostcode" :v="$v.register.RequestSTPostcode" label="Property postcode"/>

                    <form-date :time="false" dateFormat="Y-m-d" altFormat="Y-m-d" class="mb-3" v-model="register.RequestSTStartDate" :v="$v.register.RequestSTStartDate" label="Purchase date"/>
                </template>

                <template v-if="register.RequestType === 'Tenant'">
                    <div class="mb-5">
                        <div class="text-muted">Please provide the following information about you. In the second section you will be able to give details of the new Sub-tenant:</div>
                        <form-input class="mb-3" v-model="register.RequestTitle" :v="$v.register.RequestTitle" label="Title"/>
                        <form-input class="mb-3" v-model="register.RequestForenames" :v="$v.register.RequestForenames" label="First name"/>
                        <form-input class="mb-3" v-model="register.RequestSurname" :v="$v.register.RequestSurname" label="Last name"/>
                        <form-input class="mb-3" type="email" v-model="register.RequestEmail" :v="$v.register.RequestEmail" label="Email address"/>
                        <form-input class="mb-3" type="tel" v-model="register.RequestPhone" :v="$v.register.RequestPhone" label="Phone number"/>
                    </div>
                    <div class="text-muted">Now please provide details for the new Sub-tenant:</div>
                    <form-input class="mb-3" v-model="register.RequestSTTitle" :v="$v.register.RequestSTTitle" label="Title"/>
                    <form-input class="mb-3" v-model="register.RequestSTForenames" :v="$v.register.RequestSTForenames" label="First name"/>
                    <form-input class="mb-3" v-model="register.RequestSTSurname" :v="$v.register.RequestSTSurname" label="Last name"/>
                    <form-input class="mb-3" type="email" v-model="register.RequestSTEmail" :v="$v.register.RequestSTEmail" label="Email address"/>
                    <form-input class="mb-3" type="tel" v-model="register.RequestSTPhone" :v="$v.register.RequestSTPhone" label="Phone number"/>
                    <form-input class="mb-3" v-model="register.RequestSTLettingAgent" :v="$v.register.RequestSTLettingAgent" label="My Letting Agent"/>
                    <form-input class="mb-3" v-model="register.RequestSTAddressLine1" :v="$v.register.RequestSTAddressLine1" label="Property address"/>
                    <form-input class="mb-3" v-model="register.RequestSTPostcode" :v="$v.register.RequestSTPostcode" label="Property postcode"/>

                    <form-date :time="false" dateFormat="Y-m-d" altFormat="Y-m-d" class="mb-3" v-model="register.RequestSTStartDate" :v="$v.register.RequestSTStartDate" label="Assured Shorthold Tenancy start date"/>
                </template>

                <template v-if="register.RequestType === 'SubTenant'">
                    <div class="text-muted" v-if="register.RequestType === 'SubTenant'">Please provide the following information about yourself. Please note that once your account is confirmed it will be possible to add additional names:</div>

                    <form-input class="mb-3" v-model="register.RequestSTTitle" :v="$v.register.RequestSTTitle" label="Title"/>
                    <form-input class="mb-3" v-model="register.RequestSTForenames" :v="$v.register.RequestSTForenames" label="First name"/>
                    <form-input class="mb-3" v-model="register.RequestSTSurname" :v="$v.register.RequestSTSurname" label="Last name"/>
                    <form-input class="mb-3" type="email" v-model="register.RequestSTEmail" :v="$v.register.RequestSTEmail" label="Email address"/>
                    <form-input class="mb-3" type="tel" v-model="register.RequestSTPhone" :v="$v.register.RequestSTPhone" label="Phone number"/>
                    <form-input class="mb-3" v-model="register.RequestSTLettingAgent" :v="$v.register.RequestSTLettingAgent" label="My Letting Agent"/>
                    <form-input class="mb-3" v-model="register.RequestSTAddressLine1" :v="$v.register.RequestSTAddressLine1" label="Property address"/>
                    <form-input class="mb-3" v-model="register.RequestSTPostcode" :v="$v.register.RequestSTPostcode" label="Property postcode"/>

                    <form-date :time="false" dateFormat="Y-m-d" altFormat="Y-m-d" class="mb-3" v-model="register.RequestSTStartDate" :v="$v.register.RequestSTStartDate" label="My Assured Shorthold Tenancy start date"/>
                </template>

                <template v-if="register.RequestType === 'Letting'">
                    <div class="mb-5">
                        <div class="text-muted">Please provide the following information about you. In the second section you will be able to give details of the new Sub-tenant:</div>
                        <form-input class="mb-3" v-model="register.RequestCompanyName" :v="$v.register.RequestCompanyName" label="Letting Agency name"/>
                        <form-input class="mb-3" v-model="register.RequestTitle" :v="$v.register.RequestTitle" label="Title"/>
                        <form-input class="mb-3" v-model="register.RequestForenames" :v="$v.register.RequestForenames" label="First name"/>
                        <form-input class="mb-3" v-model="register.RequestSurname" :v="$v.register.RequestSurname" label="Last name"/>
                        <form-input class="mb-3" type="email" v-model="register.RequestEmail" :v="$v.register.RequestEmail" label="Email address"/>
                        <form-input class="mb-3" type="tel" v-model="register.RequestPhone" :v="$v.register.RequestPhone" label="Phone number"/>
                        <form-checkbox v-model="letting_terms" :v="$v.letting_terms" text="I confirm that I am acting with the authority of the Leaseholder / Owner of the property"/>
                    </div>
                    <div class="text-muted">Now please provide details for the new Sub-tenant:</div>
                    <form-input class="mb-3" v-model="register.RequestSTTitle" :v="$v.register.RequestSTTitle" label="Title"/>
                    <form-input class="mb-3" v-model="register.RequestSTForenames" :v="$v.register.RequestSTForenames" label="First name"/>
                    <form-input class="mb-3" v-model="register.RequestSTSurname" :v="$v.register.RequestSTSurname" label="Last name"/>
                    <form-input class="mb-3" type="email" v-model="register.RequestSTEmail" :v="$v.register.RequestSTEmail" label="Email address"/>
                    <form-input class="mb-3" type="tel" v-model="register.RequestSTPhone" :v="$v.register.RequestSTPhone" label="Phone number"/>
                    <form-input class="mb-3" v-model="register.RequestSTAddressLine1" :v="$v.register.RequestSTAddressLine1" label="Property address"/>
                    <form-input class="mb-3" v-model="register.RequestSTPostcode" :v="$v.register.RequestSTPostcode" label="Property postcode"/>

                    <form-date :time="false" dateFormat="Y-m-d" altFormat="Y-m-d" class="mb-3" v-model="register.RequestSTStartDate" :v="$v.register.RequestSTStartDate" label="Assured Shorthold Tenancy start date"/>
                </template>

                <form-checkbox v-model="terms" :v="$v.terms" text="I agree to the terms of the <a target='_blank' class='text-primary' href=https://gingerenergy.co.uk/privacy-policy/>Privacy Policy</a>"/>
                <div class="text-center mt-4">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.register}" @click.prevent="submitRegister">Register</button>
                </div>
            </template>
        </form>
    </div>
</template>

<script>

const { required, email, sameAs } = require('vuelidate/lib/validators')

export default {
    name: 'Register',
    data () {
        return {
            form: 'register',
            loading: {
                register: false,
            },
            step: 1,
            register: {
                Email: '',
                // PortalPostType: '',
                // SupplyType: '',
                // ReadValue: '',
                // PostDate: '',
                // PostEmail: '',
                RequestType: '',
                RequestCompanyName: '',
                RequestTitle: '',
                RequestForenames: '',
                RequestSurname: '',
                RequestEmail: '',
                RequestPhone: '',
                RequestSTTitle: '',
                RequestSTForenames: '',
                RequestSTSurname: '',
                RequestSTEmail: '',
                RequestSTPhone: '',
                RequestSTLettingAgent: '',
                RequestSTAddressLine1: '',
                RequestSTPostcode: '',
                RequestSTStartDate: '',
            },
            success: null,
            terms: false,
            letting_terms: false,
        }
    },
    watch: {
        'register.RequestEmail'(val) {
            if (this.register.RequestType === 'Tenant' || this.register.RequestType === 'Leaseholder' || this.register.RequestType === 'Letting') {
                this.register.Email = val;
            }
        },
        'register.RequestSTEmail'(val) {
            if (this.register.RequestType === 'SubTenant') {
                this.register.Email = val;
            }
        }
    },
    validations() {
        let rules = {
            terms: { sameAs: sameAs( () => true ) },
            letting_terms: {},
            register: {
                Email: { required, email },
                // PortalPostType: { required },
                // SupplyType: { required },
                // ReadValue: { required },
                // PostDate: { required },
                // PostEmail: { required },
                RequestCompanyName: {},
                RequestTitle: {},
                RequestForenames: {},
                RequestSurname: {},
                RequestEmail: {},
                RequestPhone: {},
                RequestType: { required },
                RequestSTTitle: { required },
                RequestSTForenames: { required },
                RequestSTSurname: { required },
                RequestSTEmail: { required, email },
                RequestSTPhone: { required },
                RequestSTLettingAgent: { },
                RequestSTAddressLine1: { required },
                RequestSTPostcode: { required },
                RequestSTStartDate: { required },
            },
        }

        if (this.register.RequestType === 'Letting') {
            rules.letting_terms = { sameAs: sameAs( () => true ) }
            rules.register.RequestCompanyName = {required}
            rules.register.RequestTitle = {required}
            rules.register.RequestForenames = {required}
            rules.register.RequestSurname = {required}
            rules.register.RequestEmail = {required}
            rules.register.RequestPhone = {required}
        }

        if (this.register.RequestType === 'Tenant') {
            rules.register.RequestTitle = {required}
            rules.register.RequestForenames = {required}
            rules.register.RequestSurname = {required}
            rules.register.RequestEmail = {required}
            rules.register.RequestPhone = {required}
        }

        if (this.register.RequestType === 'Leaseholder') {
            rules.register.RequestTitle = {required}
            rules.register.RequestForenames = {required}
            rules.register.RequestSurname = {required}
            rules.register.RequestEmail = {required}
            rules.register.RequestPhone = {required}
            rules.register.RequestSTTitle = {  }
            rules.register.RequestSTForenames = {  }
            rules.register.RequestSTSurname = {  }
            rules.register.RequestSTEmail = {  }
            rules.register.RequestSTPhone = {  }
        }

        // if (this.register.RequestType === 'Tenant' || this.register.RequestType === 'SubTenant') {
        //     rules.register.RequestSTLettingAgent = {required}
        // }

        return rules;
    },
    created () {
        $('body').addClass('form')
    },
    mounted () {
        if (this.$auth.getSession()) {
            this.$router.push({ name: 'dashboard' })
        } else {
            $('#preloader').fadeOut('slow')
        }
    },
    methods: {
        submitRegister () {
            this.$v.$touch()
            if (this.$v.$invalid === true) {
                console.log(this.$v)
                return false
            }
            this.loading.register = true;
            this.$http.get('/userpostregistrationrequest', {
                noAuth: true,
                params: this.register
            })
                .then((res) => {
                    this.loading.register = false
                    this.success = true;
                })
                .catch((error) => {
                    console.log(error)
                    this.success = false;
                    this.loading.register = false
                })
        },
    },
}
</script>
