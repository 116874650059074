<template>
    <div class="container" style="max-width: 900px;">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Make a payment</p>

        <property-details class="mb-3"/>
<!--        <property-select class="mb-3"/>-->

    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'MakePayment',
    components: { PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,

        }),
    },
    mounted () {
    },
    methods: {

    }
}
</script>
