import Vue from 'vue'
import Vuelidate from 'vuelidate'
import Auth from '@/plugins/Auth'
import Utils from '@/plugins/Utils'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VueToastr from 'vue-toastr'
import VueResource from 'vue-resource'
import VShowSlide from 'v-show-slide'
import VueEvents from 'vue-events'
import Btn from './components/core/Btn.vue'
import FormBtn from './components/core/FormBtn.vue'
import FormInput from './components/core/FormInput.vue'
import FormDate from './components/core/FormDate.vue'
import FormCheckbox from './components/core/FormCheckbox.vue'
import FormGroup from './components/core/FormGroup.vue'
import FormSelect from './components/core/FormSelect.vue'
import FormTextarea from './components/core/FormTextarea.vue'
import VTooltip from 'v-tooltip'

import VueFlatPickr from 'vue-flatpickr-component';
Vue.use(VueFlatPickr);

import acl from './acl'
import router from './router'
import store from './store'
import App from './App.vue'

import './scss/style.scss'
import './filters'

Vue.component('btn', Btn)
Vue.component('form-btn', FormBtn)
Vue.component('form-input', FormInput)
Vue.component('form-date', FormDate)
Vue.component('form-checkbox', FormCheckbox)
Vue.component('form-group', FormGroup)
Vue.component('form-select', FormSelect)
Vue.component('form-textarea', FormTextarea)

Vue.use(VuejsDialog)
Vue.use(VShowSlide)
Vue.use(VueEvents)
Vue.use(Vuelidate)
Vue.use(Auth)
Vue.use(Utils)
Vue.use(VueResource)
Vue.use(VTooltip)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueToastr, {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: false,
    showDuration: '300',
    hideDuration: '500',
    timeOut: '8000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'show',
    hideMethod: 'hide',
})

const baseUrl = process.env.VUE_APP_API_BASE_URL

Vue.http.interceptors.push((request, next) => {
    if (request.url[0] === '/') {
        request.headers.set('APIKey', '4E822476-4CBA-4AC0-8DA8-691CE3C7891C')
        const session = Vue.auth.getSession()
        if (session && !request.noAuth) {
            request.params['session'] = session.sessionID;
            request.params['userid'] = session.userID;
        }

        request.url = `${baseUrl}${request.url}`
    }

    next(function (res) {
        if (res.status === 401 || res.status === 403 || res.body.errorNumber === 5231 || res.body.errorNumber === 5232 || res.body.errorNumber === 4501) {
            this.$auth.destroySession()
            this.$store.commit('logout', this)
            this.$acl.change('public')
            this.$router.push({ name: 'login' })
        }
        if (res.body.errorNumber === 4501) {
            return
        }
        if (!request.noMessage && typeof res.body.userMessage !== 'undefined' && typeof res.body.userMessage === 'string' && res.body.userMessage.length > 0) {
            if (res.body.errorNumber) {
                this.$toastr.e(res.body.userMessage, res.body.errorText)
            }else{
                this.$toastr.s(res.body.userMessage)
            }
        }else if (!request.noMessage && typeof res.body.errorText !== 'undefined' && typeof res.body.errorText === 'string' && res.body.errorText.length > 0) {
            this.$toastr.e(res.body.errorText)
        }
    })
})

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

export default new Vue({
    router,
    store,
    acl,
    render: (h) => h(App),
}).$mount('#app')
