<template>
    <div class="container" style="max-width: 900px;">
<!--        <h1 class="page-title">My statements</h1>-->
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Here are your statements</p>

<!--        <property-select class="mb-3"/>-->
        <property-details class="mb-3"/>

        <div class="inline-form mb-4 align-items-end">
            <form-date :size="'sm'" :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="startdate" label="From date"/>
            <form-date :size="'sm'" :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="enddate" label="To date"/>
            <form-btn :size="'sm'" href="#" @click="clearFilters" class="btn btn-outline-secondary">Clear</form-btn>
        </div>
<!--        <div class="balance-box">-->
<!--            <div class="balance-box__header">-->
<!--                <div class="balance-box__text">Your current balance</div>-->
<!--                <div class="balance-box__amount">$155.70</div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mb-4" style="text-align: right">-->
<!--            <a class="text-secondary">Show workings</a>-->
<!--        </div>-->
        <div :class="{'loading': loading.data}" style="min-height: 80px">
            <div v-for="statement in statements">
                <statement-item :statement="statement" :property="selected_property"/>
            </div>
        </div>
        <div class="text-center mb-4">
            <a @click.prevent="loadMore" :class="{'loading': loading.load_more}" v-if="loading.load_more || pagination.actualPageNumber < pagination.numberOfPages" class="btn btn-sm btn-secondary">Load more</a>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'
import StatementItem from './StatementItem'

export default {
    name: 'Statements',
    components: { StatementItem, PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: true,
                load_more: false,
            },
            statements: [],
            filters: {
                rowsperpage: 10,
                pagenumber: 1,
            },
            startdate: '',
            enddate: '',
            pagination: {
                actualPageNumber: 0,
                numberOfPages: 0,
            }
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    watch: {
        startdate() {
            this.filters.pagenumber = 1;
            this.statements = [];
            this.loading.data = true;
            this.getStatements()
        },
        enddate() {
            this.filters.pagenumber = 1;
            this.statements = [];
            this.loading.data = true;
            this.getStatements()
        }
    },
    mounted () {
        this.getStatements();
    },
    methods: {
        clearFilters() {
            this.startdate = '';
            this.enddate = '';
            // this.refreshStatements()
        },
        refreshStatements () {
            this.filters.pagenumber = 1;
            this.statements = [];
            this.loading.data = true;
            this.getStatements()
        },
        loadMore() {
            if (this.loading.load_more) {
                return;
            }
            this.loading.load_more = true
            this.filters.pagenumber++
            this.getStatements()
        },
        getStatements () {
            let filters = this.filters
            filters.propertyid = this.selected_property.billingPropertyID;
            filters.stid = this.selected_property.billingTenantID;
            filters.lhid = this.selected_property.billingOwnerID;
            filters.startdate = this.startdate ? this.startdate : '';
            filters.enddate = this.enddate ? this.enddate : '';

            this.$http.get('/userstatements', { params: filters })
                .then((res) => {
                    this.statements = this.statements.concat(res.body.statementList)
                    this.pagination.actualPageNumber = res.body.actualPageNumber;
                    this.pagination.numberOfPages = res.body.numberOfPages;
                    this.loading.data = false
                    this.loading.load_more = false
                })
                .catch(() => {
                    this.loading.data = false
                    this.loading.load_more = false
                })
        },
    }
}
</script>
