var render = function (_h,_vm) {var _c=_vm._c;return _c('form-group',{class:[_vm.data.staticClass],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('div',{staticClass:"input-group"},[(!!_vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend")],2)]):_vm._e(),_c('input',{staticClass:"form-control",class:{
                    'form-control-lg': _vm.props.size === 'lg',
                    'form-control-sm': _vm.props.size === 'sm',
                    'form-control-xs': _vm.props.size === 'xs',
                    'is-invalid' : _vm.props.v ? _vm.props.v.$error : null
                },attrs:{"type":_vm.props.type ? _vm.props.type: 'text',"placeholder":_vm.props.placeholder,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly,"required":_vm.props.required,"step":_vm.props.step,"name":_vm.props.name},domProps:{"value":_vm.props.value},on:{"input":function ($event) {
                   if(_vm.listeners.input){
                       var value = $event.target.value;
                       // if(props.decimals !== undefined){
                       //      value = value.match(/^\d+\.?\d{0,0}/)
                       // }
                       _vm.listeners.input(value);
                   }
                   // listeners.input && listeners.input($event.target.value);
                   if(_vm.props.v){
                       _vm.props.v.$touch();
                   }
               },"keyup":function($event){_vm.listeners.keyup && _vm.listeners.keyup($event)},"keypress":function($event){_vm.listeners.keypress && _vm.listeners.keypress($event)},"keydown":function($event){_vm.listeners.keydown && _vm.listeners.keydown($event)}}}),(!!_vm.$slots.append)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("append")],2)]):_vm._e()])])}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }