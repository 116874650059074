<template>
    <div>
        <div class="select-box">
            <div class="select-box__selected-item" @click="expanded = !expanded" :class="{'expanded': expanded}">
                <div class="select-box__text">
                    {{ selected_property ? ('[' + selected_property.addressLine1 + ', ' + selected_property.addressLine2 + ']') : 'Select property' }}
                </div>
                <div class="select-box__caret">
                    <i class="flaticon-chevron"></i>
                </div>
            </div>
        </div>
        <div v-show-slide="expanded">
            <div class="select-box">
                <div class="select-box__item" :class="{selected: property === selected_property }" v-for="property in properties">
                    <a @click="selectProperty(property)" :class="{'selected': property === selected_property }">{{ property.addressLine1 + ', ' + property.addressLine2 }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'PropertySelect',
    data () {
        return {
            expanded: false,
        }
    },
    computed: {
        ...mapState({
            properties: (state) => state.properties,
            selected_property: (state) => state.selected_property,
        }),
    },
    methods: {
        selectProperty (property) {
            this.$store.commit('selectProperty', property)
            this.expanded = false;
        },
    }
}
</script>
