<template>
    <div class="container" style="max-width: 900px;">
        <h1 class="page-title">Contact us</h1>
        <template v-if="selected_property">
            <form-input class="mb-2" v-model="contact.title" :v="$v.contact.title" type="text" placeholder="Subject"/>
            <form-textarea class="mb-4" v-model="contact.message" :v="$v.contact.message" placeholder="Message"/>
            <!--        <div class="text-muted mb-4">-->
            <!--            <small style="font-size: 16px">Alternatively, you could email us at <a href="mailto:billingteam@gingerenergy.co.uk">billingteam@gingerenergy.co.uk</a> or call us on <a href="tel:03453073433">0345 307 3433</a>.</small>-->
            <!--        </div>-->
            <div class="text-center mb-4">
                <btn class="btn-outline-secondary --w350" :loading="loading.send" @click="send">Send</btn>
            </div>
        </template>
        <div class="empty-text" v-else>
            Please select a property
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

const { required } = require('vuelidate/lib/validators')

export default {
    name: 'Contact',
    data () {
        return {
            loading: {
                send: false,
            },
            contact: {
                user_id: '',
                title: '',
                message: '',
            },
        }
    },
    validations: {
        contact: {
            title: { required },
            message: { required },
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    methods: {
        send () {
            this.$v.contact.$touch()
            if (this.$v.contact.$invalid === true) {
                return false
            }
            const session = this.$auth.getSession()
            this.contact.user_id = session.userID
            this.loading.send = true
            this.$http.get('/userpostdata', {params :{
                    propertyid: this.selected_property.billingPropertyID,
                    postType: 'Note',
                    message: `${this.contact.title}
${this.contact.message}`
                }})
                .then(() => {
                    this.contact.title = ''
                    this.contact.message = ''
                    this.$v.contact.$reset();
                    this.$toastr.s('Message sent')
                    this.loading.send = false
                })
                .catch((e) => {
                    console.log(e)
                    this.loading.send = false
                })
        }
    }
}
</script>
