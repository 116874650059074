var render = function (_h,_vm) {var _c=_vm._c;return _c('form-group',{class:[_vm.data.staticClass],attrs:{"label":_vm.props.label,"help":_vm.props.help}},[_c('select',{staticClass:"form-control",class:{
                    'form-control-lg': _vm.props.size === 'lg',
                    'form-control-sm': _vm.props.size === 'sm',
                    'form-control-xs': _vm.props.size === 'xs',
                     'is-invalid' : _vm.props.v ? _vm.props.v.$error : null
                },attrs:{"placeholder":_vm.props.placeholder,"disabled":_vm.props.disabled,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value},on:{"change":function ($event) {
                _vm.listeners.input && _vm.listeners.input($event.target.value);
                _vm.listeners.change && _vm.listeners.change($event.target.value);
                if(_vm.props.v){
                   _vm.props.v.$touch();
                }
            }}},[(_vm.props.placeholder)?_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(_vm._s(_vm.props.placeholder))]):_vm._e(),_vm._l((_vm.props.options),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])})],2)])}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }