<template>
    <div>
        <div class="select-box">
            <div class="select-box__selected-item" @click="expanded = !expanded" :class="{'expanded': expanded}">
                <div class="select-box__text">
                    {{ selected_site ? ('[' + selected_site.addressLine1 + ', ' + selected_site.addressLine2 + ']') : 'Select site' }}
                </div>
                <div class="select-box__caret">
                    <i class="flaticon-chevron"></i>
                </div>
            </div>
        </div>
        <div v-show-slide="expanded">
            <div class="select-box">
                <div class="select-box__item" :class="{selected: site === selected_site }" v-for="site in sites">
                    <a @click="selectSite(site)" :class="{'selected': site === selected_site }">{{ site.addressLine1 + ', ' + site.addressLine2 }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    name: 'SiteSelect',
    data () {
        return {
            expanded: false,
        }
    },
    computed: {
        ...mapState({
            sites: (state) => state.sites,
            selected_site: (state) => state.selected_site,
        }),
    },
    methods: {
        selectSite (site) {
            this.$store.commit('selectSite', site)
            this.expanded = false;
        },
    }
}
</script>
