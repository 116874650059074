<template functional>
    <div class="form-group">
        <label style="display: block">&nbsp;</label>
        <btn :class="data.staticClass"
             :size="props.size"
             v-on:click="listeners.click && listeners.click()"
             :disabled="props.disabled"
             :readonly="props.readonly"
             :loading="props.loading"
        ><slot></slot></btn>
    </div>
</template>

<script>

export default {
  name: 'FormBtn',
};
</script>
