<template>
    <div class="container" style="max-width: 900px;">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description"></p>

<!--        <property-select class="mb-3"/>-->
        <property-details class="mb-3"/>

        <p class="text-muted">You can choose to pay your entire outstanding balance, or a custom amount. The amount you are seeing here is taken from your last statement and doesn't take into account any subsequent invoices.</p>
        <p class="text-muted">When you click ‘Pay securely’ below, you will be taken to the Barclaycard payment platform where your payment will be processed.</p>
        <form ref="form" method="POST" :action="url">
            <input type="hidden" name="access_key" :value="access_key">
            <input type="hidden" name="profile_id" :value="profile_id">

            <input type="hidden" name="bill_to_forename" :value="user.firstname">
            <input type="hidden" name="bill_to_surname" :value="user.lastname">
<!--            <input type="hidden" name="bill_to_address_line1" :value="selected_property.addressLine1">-->
<!--            <input type="hidden" name="bill_to_address_city" :value="selected_property.addressLine2">-->
<!--            <input type="hidden" name="bill_to_address_postal_code" :value="selected_property.addressPostcode">-->
            <input type="hidden" name="bill_to_address_country" value="GB">

<!--            <input type="hidden" name="bill_to_forename" value="">-->
<!--            <input type="hidden" name="bill_to_surname" value="">-->
            <input type="hidden" name="bill_to_address_line1" value="">
            <input type="hidden" name="bill_to_address_city" value="">
            <input type="hidden" name="bill_to_address_postal_code" value="">
<!--            <input type="hidden" name="bill_to_address_country" value="">-->

            <input type="hidden" name="bill_to_email" :value="user.email">

            <input type="hidden" name="transaction_uuid" :value="uuid">
            <input type="hidden" name="signed_field_names" :value="signed_field_names">
            <input type="hidden" name="unsigned_field_names" value="">
            <input type="hidden" name="signed_date_time" :value="date">

            <input type="hidden" name="locale" value="en">

            <input type="hidden" name="transaction_type" value="sale">
            <input type="hidden" name="reference_number" :value="selected_property.lhstReference">

            <form-input label="Amount" required type="number" v-model="amount" name="amount" step=".01" @keypress.enter="startPayment">
                <template #append>£</template>
            </form-input>
            <input type="hidden" name="currency" value="GBP">
            <input type="hidden" name="submit" value="Submit">

            <input type="hidden" name="signature" :value="signature">

            <button class="--w350 mt-2 btn btn-primary" :class="{'loading': loading.payment}" type="submit" @click.prevent="startPayment">Pay securely</button>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'
import moment from "moment";
import {minValue} from "vuelidate/lib/validators";
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'crypto-js';

export default {
    name: 'PayByCard',
    components: { PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: true,
                payment: false,
            },
            lastStatement: null,
            amount: 0,
            access_key: process.env.VUE_APP_CARD_ACCESS_KEY,
            profile_id: process.env.VUE_APP_CARD_PROFILE_ID,
            url: process.env.VUE_APP_CARD_URL,
            signed_field_names: '',
            signature: null,
            uuid: null,
            date: null,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    validations: {
        amount: { minValue: minValue(1) },
    },
    mounted () {
        this.uuid = uuidv4();
        this.date = moment().utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        this.getStatements();
    },
    methods: {
        startPayment($event) {
            this.$v.$touch()
            if (this.$v.$invalid === true) {
                $event.preventDefault();
                return false
            }
            if (!this.signature) {
                $event.preventDefault();
            }
            this.sign();
            this.loading.payment = true;

            setTimeout(() => {
                const form = this.$refs.form;
                form.requestSubmit();
            }, 100);

            setTimeout(() => {
                this.loading.payment = false;
            }, 10000);
        },
        getStatements () {
            let filters = {}
            filters.propertyid = this.selected_property.billingPropertyID;
            filters.stid = this.selected_property.billingTenantID;
            filters.lhid = this.selected_property.billingOwnerID;
            filters.startdate = '';
            filters.enddate = '';

            this.$http.get('/userstatements', { params: filters })
                .then((res) => {
                    let statements = res.body.statementList.sort(function (a, b) {
                        return moment(b.statementDate, 'DD/MM/YYYY').isAfter(moment(a.statementDate, 'DD/MM/YYYY')) ? 1 : -1
                    });

                    this.lastStatement = statements[0] ? statements[0] : null
                    if (this.lastStatement) {
                        this.amount = parseFloat(this.lastStatement.closingBalance)
                    }
                    this.loading.data = false;
                })
                .catch(() => {
                    this.loading.data = false
                })
        },
        async sign() {
            let signed_field_names = [];
            await this.$refs.form.forEach((el) => {
                if (!el.name || ['signature', 'submit'].indexOf(el.name) > -1) {

                }else {
                    signed_field_names.push(el.name);
                }
            });
            this.signed_field_names = signed_field_names.join(',');

            let $params = [];
            this.$refs.form.forEach((el) => {
                if (!el.name) {

                }else if (['signature', 'submit'].indexOf(el.name) > -1) {

                }else if (el.name === 'signed_field_names') {
                    $params.push(el.name + '=' + this.signed_field_names);
                }else {
                    $params.push(el.name + '=' + el.value);
                }
            });

            this.signature = await this.signData($params.join(','));
        },
        async signData($data) {
            var hash = CryptoJS.HmacSHA256($data, process.env.VUE_APP_CARD_SECRET_KEY);
            return hash.toString(CryptoJS.enc.Base64);
        },
    }
}
</script>
