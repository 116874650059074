import Vue from 'vue'
import moment from 'moment'

Vue.filter('moment', (value, format, parseFormat) => {
    if (!value) {
        return '-'
    }
    if (typeof value === 'object' && typeof value.date !== 'undefined') {
        value = value.date
    }
    format = format || 'DD MMM YYYY, HH:mm'
    let abbr = ''
    if (format.indexOf('zz') > -1) {
        abbr = 'UTC'
    }
    if (parseFormat) {
        return `${moment(value, parseFormat).format(format)} ${abbr}`
    }
    return `${moment(value).format(format)} ${abbr}`
})

Vue.filter('momentTime', (value, format) => moment(value, 'HH:mm:ss').format(format))
Vue.filter('momentAgo', (value) => moment(value).fromNow())

Vue.filter('percentage', (value) => `${parseFloat((value * 100).toFixed(1))}%`)

Vue.filter('beautify', (value) => {
    if (!value) {
        return value
    }
    value = value.replace(/[_-]/g, ' ')
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('ucfirst', (value) => {
    if (!value) {
        return value
    }
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('toPrice', (value, currency) => {

    if (typeof value === 'undefined' || value === null) {
        return '-'
    }
    value = String(value).replace(',', '')
    if (!(`${value}`).includes('e')) {
        value = +(`${Math.round(`${value}e+${2}`)}e-${2}`)
    } else {
        const arr = (`${value}`).split('e')
        let sig = ''
        if (+arr[1] + 2 > 0) {
            sig = '+'
        }
        value = +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + 2}`)}e-${2}`)
    }

    if (typeof currency === 'undefined') {
        currency = '£'
    }

    if (value < 0 && currency) {
        value = parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return `-${currency}${value.toString(2).replace('-', '')}`
    }else if (currency) {
        value = parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return `${currency}${value}`
    }
    return value
})

Vue.filter('parsePrice', (value) => {
    if (typeof value === 'undefined' || value === null) {
        return 0
    }
    value = String(value).replace(',', '')
    if (!(`${value}`).includes('e')) {
        value = +(`${Math.round(`${value}e+${2}`)}e-${2}`)
    } else {
        const arr = (`${value}`).split('e')
        let sig = ''
        if (+arr[1] + 2 > 0) {
            sig = '+'
        }
        value = +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + 2}`)}e-${2}`)
    }
    return parseFloat(value)
})

