const prefix = ''
const baseUrl = process.env.VUE_APP_API_BASE_URL

const AuthPlugin = {
    setSession (sessionID, userID, sessionExpires) {
        localStorage.setItem(`${prefix}sessionID`, sessionID)
        localStorage.setItem(`${prefix}userID`, userID)
        localStorage.setItem(`${prefix}sessionExpires`, sessionExpires)
    },
    destroySession () {
        localStorage.removeItem(`${prefix}sessionID`)
        localStorage.removeItem(`${prefix}userID`);
        localStorage.removeItem(`${prefix}sessionExpires`);
    },
    getSession () {
        const sessionID = localStorage.getItem(`${prefix}sessionID`)
        if (!sessionID) {
            return null
        }
        const sessionExpires = localStorage.getItem(`${prefix}sessionExpires`)
        if (sessionExpires < new Date()) {
            return null;
        }
        const userID = localStorage.getItem(`${prefix}userID`)
        return {
            sessionID,
            userID
        }
    },
    setCurrentRoute (route, id) {
        if (route.name === 'login') {
            return false
        }
        sessionStorage.setItem(`${prefix}redirectUserId`, id)
        sessionStorage.setItem(`${prefix}redirectLoginPathName`, route.name)
        sessionStorage.setItem(`${prefix}redirectLoginParams`, JSON.stringify(route.params))
        sessionStorage.setItem(`${prefix}redirectLoginQuery`, JSON.stringify(route.query))
    },
    redirectToLastRoute (router, id) {
        if (sessionStorage.getItem(`${prefix}redirectLoginPathName`) && sessionStorage.getItem(`${prefix}redirectUserId`) === id) {
            router.push({
                name: sessionStorage.getItem(`${prefix}redirectLoginPathName`),
                params: JSON.parse(sessionStorage.getItem(`${prefix}redirectLoginParams`)),
                query: JSON.parse(sessionStorage.getItem(`${prefix}redirectLoginQuery`)),
            })
            sessionStorage.removeItem(`${prefix}redirectLoginPathName`)
            sessionStorage.removeItem(`${prefix}redirectLoginParams`)
            sessionStorage.removeItem(`${prefix}redirectLoginQuery`)
        } else {
            router.push({ name: 'home' })
        }
    },
}

export default function (Vue) {
    Vue.auth = AuthPlugin

    Object.defineProperties(Vue.prototype, {
        $auth: {
            get () {
                return Vue.auth
            },
        },
    })
}
