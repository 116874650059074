<template>
    <div class="charge-box" :class="{'expanded': expanded}">
        <div class="charge-box__header" @click="expanded = !expanded">
            <div class="charge-box__date">2nd Nov 2020</div>
            <div class="charge-box__info">1416125041000</div>
            <div class="charge-box__caret">
                <i class="flaticon-chevron"></i>
            </div>
        </div>
        <div v-show-slide="expanded">
            <div class="charge-box__body">
                <div class="charge-box__body-col">
                    <div class="charge-box__body-title">Reading</div>
                    <div class="charge-box__body-value">44847</div>
                </div>
                <div class="charge-box__body-col">
                    <div class="charge-box__body-title">Consumption</div>
                    <div class="charge-box__body-value">16.09p/kWh 21.43p/day<br>
                        (All rates inc. VAT)</div>
                </div>
            </div>
            <div class="charge-box__footer">
                <div class="charge-box__footer-col">
                    <div class="charge-box__footer-title">Standing charge</div>
                    <div class="charge-box__footer-value">£35.47</div>
                </div>
                <div class="charge-box__footer-col">
                    <div class="charge-box__footer-title">Standing charge</div>
                    <div class="charge-box__footer-value">£35.47</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChargeBox',
    props: ['charge'],
    data () {
        return {
            expanded: false,
        }
    }
}
</script>
