<template>
    <div class="container page" style="max-width: 900px;" :class="{'loading-btn': loading.data}">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Select the site you want to manage</p>
        <a href="#" class="mb-2" v-for="site in sites" @click.prevent="selectSite(site)">
            <site-details :site="site"/>
        </a>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SiteSelect from '../components/SiteSelect'
import SiteDetails from '../components/SiteDetails'

export default {
    name: 'SiteSelect',
    components: { SiteDetails, SiteSelect },
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            sites: (state) => state.sites,
            selected_site: (state) => state.selected_site,
        }),
    },
    methods: {
        selectSite (site) {
            this.$store.commit('selectSite', site)
            this.$router.push({ name: 'dashboard' });
        }
    }
}
</script>
