<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass, {'error' : props.v ? props.v.$error : null}]">
        <div class="n-chk">
            <label class="new-control new-checkbox checkbox-primary">
                <input type="checkbox" :checked="props.value" class="new-control-input"
                       v-on:change="($event) => {
                      listeners.input && listeners.input($event.target.checked);
                      if(props.v){
                           props.v.$touch();
                      }
                  }">
                <span class="new-control-indicator"></span> <span v-html="props.text"></span>
            </label>
        </div>
    </form-group>
</template>

<script>
export default {
  name: 'FormCheckbox',
};
</script>
