<template>
    <div style="flex: 1 0 auto;
                            display: flex;
                            flex-direction: column;">
        <div id="load_screen" v-if="loading">
            <div class="loader">
                <div class="loader-content">
                    <svg id="loyal_loader" width="300px" height="91px" viewBox="0 0 300 91" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
                         style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <g transform="matrix(1.5,0,0,1.5,0,0)">
                    <g>
                        <path d="M4.81,5.48L4.81,25.921L16.433,25.921L16.433,30.731L0,30.731L0,5.48L4.81,5.48Z" style="fill-rule:nonzero;"/>
                    </g>
                    <g>
                        <path
                            d="M21.643,11.893C22.845,9.889 24.449,8.286 26.052,7.083C28.056,5.881 30.06,5.48 32.465,5.48C34.87,5.48 36.874,5.881 38.878,7.083C40.882,8.285 42.485,9.889 43.287,11.893C44.489,13.897 44.89,15.901 44.89,18.306C44.89,20.711 44.489,22.715 43.287,24.719C42.085,26.723 40.481,28.326 38.878,29.128C36.874,30.33 34.87,30.731 32.465,30.731C30.06,30.731 28.056,30.33 26.052,29.128C24.048,27.926 22.445,26.322 21.643,24.719C20.441,22.715 20.04,20.711 20.04,18.306C20.04,15.901 20.441,13.897 21.643,11.893ZM26.052,21.913C26.854,23.115 27.655,23.917 28.858,24.719C30.06,25.521 31.263,25.921 32.866,25.921C34.068,25.921 35.672,25.52 36.473,24.719C37.675,23.917 38.477,23.116 39.279,21.913C40.081,20.711 40.081,19.508 40.081,17.905C40.081,16.302 39.68,15.099 39.279,13.897C38.477,12.695 37.676,11.893 36.473,11.091C35.271,10.289 34.068,9.889 32.465,9.889C31.263,9.889 29.659,10.29 28.457,11.091C27.255,11.893 26.453,12.694 25.651,13.897C24.849,15.099 24.849,16.302 24.849,17.905C24.849,19.508 25.25,20.71 26.052,21.913Z"
                            style="fill-rule:nonzero;"/>
                    </g>
                    <g>
                        <path d="M97.395,5.48L97.395,25.921L109.018,25.921L109.018,30.731L92.184,30.731L92.184,5.48L97.395,5.48Z" style="fill-rule:nonzero;"/>
                    </g>
                    <g>
                        <path d="M46.092,8.686L54.108,23.917L54.108,30.73L58.517,30.73L58.517,23.917L74.95,0.27L56.513,16.302L46.092,8.686Z" style="fill:rgb(217,10,22);fill-rule:nonzero;"/>
                    </g>
                    <g>
                        <circle cx="56.513" cy="9.889" r="2.806" style="fill:rgb(217,10,22);"/>
                    </g>
                    <path d="M130.261,5.48L130.261,10.29L119.439,10.29L119.439,15.901L128.657,15.901L128.657,20.711L119.439,20.711L119.439,30.731L114.629,30.731L114.629,5.48L130.261,5.48Z" style="fill:rgb(217,10,22);fill-rule:nonzero;"/>
                    <path d="M177.956,5.48L177.956,30.73L161.523,15.9L161.523,30.73L156.713,30.73L156.713,5.48L173.146,20.31L173.146,5.48L177.956,5.48Z" style="fill:rgb(217,10,22);fill-rule:nonzero;"/>
                    <path
                        d="M192.786,9.889C191.584,9.889 190.782,10.29 190.381,10.691C189.98,11.092 189.579,11.893 189.579,12.695C189.579,13.497 189.98,14.298 190.781,14.699C191.583,15.1 192.785,15.901 194.388,16.302C195.59,16.703 196.392,17.104 197.194,17.905C197.996,18.306 198.797,19.107 199.198,20.31C199.599,21.112 200,22.314 200,23.917C200,25.119 199.599,26.322 199.198,27.524C198.396,28.726 197.595,29.528 196.392,29.929C195.19,30.731 193.586,30.731 191.983,30.731C190.38,30.731 189.177,30.33 187.574,29.929C185.971,29.528 184.768,28.727 183.566,27.925L185.57,24.318C186.372,25.12 187.574,25.52 188.376,25.921C189.578,26.322 190.38,26.723 191.182,26.723C192.384,26.723 193.186,26.322 193.988,25.921C194.79,25.52 195.19,24.719 195.19,23.917C195.19,22.715 193.988,21.512 191.583,20.711C189.98,20.31 189.178,19.509 187.976,19.108C187.174,18.707 186.373,17.906 185.571,16.703C184.769,15.501 184.369,14.298 184.369,12.695C184.369,10.691 185.171,8.687 186.373,7.485C187.575,6.283 189.579,5.481 191.984,5.481C193.988,5.481 195.19,5.882 196.393,6.283C197.595,6.684 198.798,7.085 200,7.886L198.397,11.493C196.393,10.29 194.389,9.889 192.786,9.889Z"
                        style="fill:rgb(217,10,22);fill-rule:nonzero;"/>
                    <path d="M135.07,30.73L136.673,26.722L145.09,26.722L146.693,30.73L152.304,30.73L141.483,5.48L141.082,5.48L130.26,30.73L135.07,30.73ZM141.082,16.302L143.487,22.314L138.677,22.314L141.082,16.302Z"
                          style="fill:rgb(217,10,22);fill-rule:nonzero;"/>
                    <path d="M70.942,30.73L72.545,26.722L80.962,26.722L82.565,30.73L88.176,30.73L77.355,5.48L76.954,5.48L66.132,30.73L70.942,30.73ZM76.954,16.302L79.359,22.314L74.549,22.314L76.954,16.302Z" style="fill-rule:nonzero;"/>
                </g>
                        <g transform="matrix(0.249722,0,0,0.249722,18.9717,-150.759)">
                    <g transform="matrix(1,0,0,1,18.0479,-16.2411)">
                        <circle class="st2" cx="275.4" cy="910" r="35"/>
                    </g>
                            <g transform="matrix(1,0,0,1,18.0479,-16.2411)">
                        <circle class="st3" cx="475.4" cy="910" r="35"/>
                    </g>
                            <g transform="matrix(1,0,0,1,221.027,-16.2411)">
                        <circle class="st4" cx="475.4" cy="910" r="35"/>
                    </g>
                </g>
            </svg>
                </div>
            </div>
        </div>
        <router-view style="flex: 1 0 auto;
                            display: flex;
                            flex-direction: column;"
                     v-if="loaded"></router-view>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
    name: 'app',
    data () {
        return {
            loading: true,
            loaded: false,
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_site: (state) => state.selected_site,
        }),
    },
    created () {
        // this.getUser()
        const session = Vue.auth.getSession();
        if (session) {
            console.log(this.user.userType === 'Site')
            if (this.user.userType === 'Site') {
                this.getSites()
                if (this.selected_site) {
                    this.getProperties();
                }
            }else{
                this.getProperties();
            }
            this.$acl.change('logged');
        }else{
            this.$store.commit('logout')
            this.$auth.destroySession();
            this.$acl.change('public');
        }
        this.loading = false
        this.loaded = true

        this.$store.commit('setUserLoading', false)
    },
    methods: {
        getUser () {
            this.$store.commit('setUserLoading', true)
            this.$http.get('/api/v1/admin/auth/user/me', {
                noAlert: true,
            })
                .then((res) => {
                    this.$store.commit('setUser', res.body.response.user)
                    this.$auth.setRole('logged')
                    this.$acl.change('logged')
                    if (res.body.response.roles.length > 0) {
                        this.$role.setPermissions(res.body.response.roles[0].name, res.body.response.roles[0].permissions.map((permission) => permission.name))
                    }
                    this.loading = false
                    this.loaded = true
                    this.$store.commit('setUserLoading', false)
                    setTimeout(() => {
                        $('#preloader').fadeOut('slow')
                    }, 500)
                })
                .catch(function () {
                    this.loading = false
                    this.loaded = true
                    this.$store.commit('setUserLoading', false)
                    setTimeout(() => {
                        $('#preloader').fadeOut('slow')
                    }, 500)
                })
        },
        getProperties () {
            this.$http.get('/userproperties', {
                params: {siteId: this.selected_site ? this.selected_site.billingSiteID : null}
            })
                .then((res) => {
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error loading your properties. Please contact Ginger support.')
                        this.$store.commit('logout')
                        this.$auth.destroySession();
                        this.$acl.change('public');
                        return
                    }
                    this.$store.commit('setProperties', res.body.propertyList)
                })
                .catch(() => {
                    // this.loading.data = false
                })
        },
        getSites () {
            this.$http.get('/usersites')
                .then((res) => {
                    if (res.body.errorNumber > 0) {
                        this.$toastr.e('There was an error loading your sites. Please contact Ginger support.')
                        this.$store.commit('logout')
                        this.$auth.destroySession();
                        this.$acl.change('public');
                        return
                    }
                    this.$store.commit('setSites', res.body.siteList)
                })
                .catch((e) => {
                    console.log(e)
                })
        },
    },
}
</script>
