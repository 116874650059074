<template>
    <div class="page-wrapper">
        <header id="site-header" :class="{'opened': menuOpened}">
            <div class="container site-header">
                <div id="site-logo">
                    <router-link :to="{name: $acl.check('isLogged') ? 'login' : 'dashboard'}" rel="home">
                        <img src="/images/logo_header.png" class="logo" width="280" height="280" alt="">
                    </router-link>
                </div>
                <div class="menu-bar-inner" v-if="$acl.check('isLogged')">
                    <router-link v-if="selected_property" :to="{name: 'messages'}" tag="a" href="#" class="menu-bar">
                        <i class="flaticon-email"></i>
                        <span class="counter">{{ selected_property.infoList.length }}</span>
                    </router-link>
                    <a href="#" class="menu-bar" @click.prevent="toggleMenu">
<!--                        <i class="flaticon-menu"></i>-->
                        <span class="ham"></span>
                    </a>
                </div>
                <div id="full-screen-menu" v-if="$acl.check('isLogged')">
                    <nav class="main-navigation" role="navigation">
                        <ul class="main-menu">
                            <li class="menu-item">
                                <router-link :to="{name: 'dashboard'}" class="menu-link">Home page</router-link>
                            </li>
                            <li class="menu-item">
                                <router-link :to="{name: 'account'}" class="menu-link">My account details</router-link>
                            </li>
<!--                            <li class="menu-item" v-if="hasMoreProperties">-->
<!--                                <router-link :to="{name: 'property-select'}" class="menu-link">Select property</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item">-->
<!--                                <router-link :to="{name: 'dashboard'}" href="#" class="menu-link">My bills</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item">-->
<!--                                <router-link :to="{name: 'submit-readings'}" href="#" class="menu-link">Meter readings</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item">-->
<!--                                <router-link :to="{name: 'moving'}" href="#" class="menu-link">Moving house</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item">-->
<!--                                <router-link :to="{name: 'dashboard'}" href="#" class="menu-link">FAQ</router-link>-->
<!--                            </li>-->
<!--                            <li class="menu-item">-->
<!--                                <router-link :to="{name: 'dashboard'}" href="#" class="menu-link">About us</router-link>-->
<!--                            </li>-->
                            <li class="menu-item">
                                <router-link :to="{name: 'faq'}" href="#" class="menu-link">FAQ</router-link>
                            </li>
                            <li class="menu-item">
                                <router-link :to="{name: 'contact'}" href="#" class="menu-link">Contact us</router-link>
                            </li>
<!--                            <li class="menu-item" v-if="!$acl.check('isLogged')">-->
<!--                                <router-link :to="{name: 'login'}" href="#" class="menu-link">Login</router-link>-->
<!--                            </li>-->
                            <li class="menu-item" v-if="$acl.check('isLogged')">
                                <a href="#" @click.prevent="logout" class="menu-link">Logout</a>
                            </li>
                        </ul>
                        <div class="menu-social">
                            <ul aria-label="Social links">
<!--                                <li class="oceanwp-twitter"><a href="https://twitter.com/GingerEnergy" aria-label="Twitter (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-twitter" aria-hidden="true"></i></a></li>-->
                                <li class="oceanwp-facebook"><a href="https://www.facebook.com/gingerenergyuk/" aria-label="Facebook (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-facebook" aria-hidden="true"></i></a></li>
<!--                                <li class="oceanwp-instagram"><a href="#" aria-label="Instagram (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-instagram" aria-hidden="true"></i></a></li>-->
<!--                                <li class="oceanwp-youtube"><a href="#" aria-label="Youtube (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-youtube" aria-hidden="true"></i></a></li>-->
                                <li class="oceanwp-linkedin"><a href="https://www.linkedin.com/company/ginger-energy/" aria-label="Youtube (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-linkedin" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>

        <router-view id="site-body"/>

        <div id="site-footer">
            <div class="container footer-row">
                <div>
                    <a target="_blank" href="https://gingerenergy.co.uk/terms-and-conditions/" class="footer-item">Terms & Conditions</a>
                    <a target="_blank" href="https://gingerenergy.co.uk/privacy-policy/" class="footer-item">Privacy Policy</a>
                </div>
                <div class="footer-social">
                    <ul class="" aria-label="Social links">
<!--                        <li class="oceanwp-twitter"><a href="https://twitter.com/GingerEnergy" aria-label="Twitter (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-twitter" aria-hidden="true"></i></a></li>-->
                        <li class="oceanwp-facebook"><a href="https://www.facebook.com/gingerenergyuk/" aria-label="Facebook (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-facebook" aria-hidden="true"></i></a></li>
                        <li class="oceanwp-linkedin"><a href="https://www.linkedin.com/company/ginger-energy/" aria-label="Youtube (opens in a new tab)" target="_blank" rel="noopener noreferrer"><i class="flaticon-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
<!--            <div class="container">-->
<!--                <template v-if="selected_property">-->
<!--                    <div><b>Property ID </b>[{{ selected_property.billingPropertyID }}]</div>-->
<!--                    <div v-if="selected_property.addressLine1">{{ selected_property.addressLine1 }}</div>-->
<!--                    <div v-if="selected_property.addressLine2">{{ selected_property.addressLine2 }}</div>-->
<!--                    <div v-if="selected_property.addressLine3">{{ selected_property.addressLine3 }}</div>-->
<!--                    <div v-if="selected_property.addressLine4">{{ selected_property.addressLine4 }}</div>-->
<!--                    <div v-if="selected_property.addressLine5">{{ selected_property.addressLine5 }}</div>-->
<!--                    <div v-if="selected_property.addressPostcode">{{ selected_property.addressPostcode }}</div>-->
<!--                    <br>-->
<!--                    <div><b>Prime Authority:</b> [xxxx]</div>-->
<!--                    <div><b>Block Managing Agent:</b> [xxxxs]</div>-->
<!--                </template>-->
<!--            </div>-->
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    name: 'layout',
    data () {
        return {
            menuOpened: false
        }
    },
    created: function () {
        this.$router.beforeEach((to, from, next) => {
            this.closeMenu()
            next()
        })
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            meta: (state) => state.meta,
            selected_property: (state) => state.selected_property,
        }),
        hasMoreProperties() {
            return this.$store.getters.hasMoreProperties
        }
    },
    methods: {
        closeMenu () {
            this.menuOpened = false;
        },
        toggleMenu () {
            this.menuOpened = !this.menuOpened
        },
        logout () {
            this.$store.dispatch('logout', this)
        },
    },
}
</script>
