<template functional>
    <form-group :label="props.label" :help="props.help" v-bind:class="[data.staticClass]">
        <select class="form-control" :placeholder="props.placeholder"
                :class="{
                        'form-control-lg': props.size === 'lg',
                        'form-control-sm': props.size === 'sm',
                        'form-control-xs': props.size === 'xs',
                         'is-invalid' : props.v ? props.v.$error : null
                    }"
                :value="props.value"
                :disabled="props.disabled"
                :readonly="props.readonly"
                v-on:change="($event) => {
                    listeners.input && listeners.input($event.target.value);
                    listeners.change && listeners.change($event.target.value);
                    if(props.v){
                       props.v.$touch();
                    }
                }"
            >
            <option disabled value="" v-if="props.placeholder">{{ props.placeholder }}</option>
            <option :key="index" v-for="(option, index) in props.options" :value="option.value">{{ option.label }}</option>
        </select>
    </form-group>
</template>

<script>
export default {
  name: 'FormSelect',
};
</script>
