<template>
    <div class="container" style="max-width: 900px;">
<!--        <h1 class="page-title">My transactions</h1>-->
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Here are your transactions</p>

<!--        <property-select class="mb-3"/>-->
        <property-details class="mb-3"/>

        <div class="inline-form mb-4 align-items-end">
            <form-date :size="'sm'" :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="startdate" label="From date"/>
            <form-date :size="'sm'" :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="enddate" label="To date"/>
            <form-btn :size="'sm'" href="#" @click="clearFilters" class="btn btn-outline-secondary">Clear</form-btn>
        </div>
<!--        <div class="balance-box">-->
<!--            <div class="balance-box__header">-->
<!--                <div class="balance-box__text">Your current balance</div>-->
<!--                <div class="balance-box__amount">$155.70</div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="mb-4" style="text-align: right">-->
<!--            <a class="text-secondary">Show workings</a>-->
<!--        </div>-->
        <div :class="{'loading': loading.data}" style="min-height: 80px" v-if="transactions">
            <div v-for="transaction in transactions">
                <transaction-item :transaction="transaction" :property="selected_property"/>
            </div>
        </div>
        <div class="text-center mb-4">
            <a @click.prevent="loadMore" :class="{'loading': loading.load_more}" v-if="loading.load_more || pagination.actualPageNumber < pagination.numberOfPages" class="btn btn-sm btn-secondary">Load more</a>
        </div>
        <hr>
        <div class="text-center mb-4">
            <json-c-s-v :data="getData()" name="Transactions.csv"
                        :fields="['transactionDate', 'transactionType', 'invoiceNumber', 'accountName', 'supplyType', 'billingPeriod', 'originalTransactionValue', 'transactionValue', 'closingBalance', 'allocateToAbbreviated']"
                        :labels="{
                            transactionDate: 'Transaction date',
                            transactionType: 'Transaction type',
                            invoiceNumber: 'Invoice number',
                            accountName: 'Account name',
                            supplyType: 'Supply type',
                            billingPeriod: 'Billing period',
                            originalTransactionValue: 'Payment you made',
                            transactionValue: 'Amount',
                            closingBalance: 'Closing balance',
                            allocateToAbbreviated: 'AllocateToAbbreviated',
                        }"
            >
                <a class="btn btn-outline-primary">Download transactions list</a>
            </json-c-s-v>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'
import TransactionItem from './TransactionItem'
import JsonCSV from 'vue-json-csv'

export default {
    name: 'Transactions',
    components: { TransactionItem, PropertyDetails, PropertySelect,JsonCSV },
    data () {
        return {
            loading: {
                data: true,
                load_more: false,
            },
            transactions: [],
            filters: {
                rowsperpage: 10,
                pagenumber: 1,
            },
            startdate: '',
            enddate: '',
            pagination: {
                actualPageNumber: 0,
                numberOfPages: 0,
            }
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    watch: {
        startdate() {
            this.filters.pagenumber = 1;
            this.transactions = [];
            this.loading.data = true;
            this.getTransactions()
        },
        enddate() {
            this.filters.pagenumber = 1;
            this.transactions = [];
            this.loading.data = true;
            this.getTransactions()
        }
    },
    mounted () {
        this.getTransactions();
    },
    methods: {
        clearFilters() {
            this.startdate = '';
            this.enddate = '';
            // this.refreshTransactions()
        },
        refreshTransactions () {
            this.filters.pagenumber = 1;
            this.transactions = [];
            this.loading.data = true;
            this.getTransactions()
        },
        loadMore() {
            if (this.loading.load_more) {
                return;
            }
            this.loading.load_more = true
            this.filters.pagenumber++
            this.getTransactions()
        },
        getTransactions () {
            let filters = this.filters
            filters.propertyid = this.selected_property.billingPropertyID;
            filters.stid = this.selected_property.billingTenantID;
            filters.lhid = this.selected_property.billingOwnerID;
            filters.startdate = this.startdate ? this.startdate : '';
            filters.enddate = this.enddate ? this.enddate : '';

            this.$http.get('/usertransactions', { params: filters })
                .then((res) => {
                    this.transactions = this.transactions.concat(res.body.transactionList)
                    this.pagination.actualPageNumber = res.body.actualPageNumber;
                    this.pagination.numberOfPages = res.body.numberOfPages;
                    this.loading.data = false
                    this.loading.load_more = false
                })
                .catch(() => {
                    this.loading.data = false
                    this.loading.load_more = false
                })
        },
        getData () {
            return this.transactions.map((transaction) => {
                transaction.transactionDate = transaction.transactionDate.split(' ')[0]
                return transaction
            })
        }
    }
}
</script>
