<template>
    <div class="container pb-4" style="max-width: 900px;">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description" v-if="selected_property.portalUserRole === 'Sub tenant'">Are you moving?</p>
        <p class="page-description" v-if="selected_property.portalUserRole === 'Leaseholder'">Are You Selling or Changing Tenant?</p>

        <property-details class="mb-3"/>

        <template v-if="selected_property.portalUserRole === 'Sub tenant'">
            <div class="box mb-3">
                <div class="box__body text-center">
                    <div class="text-muted"><b class="text-primary"></b>Are you still living at {{ selected_property.addressLine1 }}?</div>
                    <div class="mt-3">
                        <btn @click="moving.still_living = true" class="--w350 mb-2" :class="moving.still_living === true ? 'btn-primary' : 'btn-outline-primary'">Yes</btn>
                        <btn @click="moving.still_living = false" class="--w350 mb-2" :class="moving.still_living === false ? 'btn-primary' : 'btn-outline-primary'">No</btn>
                    </div>
                </div>
            </div>

            <div class="box mb-3" v-show-slide="moving.still_living !== null">
                <div class="box__body text-center">
                    <div  style="max-width: 350px" class="mx-auto">
                        <div class="text-muted mb-3">
                            <template v-if="moving.still_living === true">What date does your tenancy agreement end?</template>
                            <template v-if="moving.still_living === false">What date did your tenancy agreement end?</template>
                        </div>
                        <form-date :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="moving.date" :v="$v.moving.date"/>
                        <div v-if="date_error" class="text-danger mt-2">{{ date_error }}</div>
                    </div>
                </div>
            </div>

            <div class="box mb-3" v-show-slide="moving.date && !date_error">
                <div class="box__body text-center">
                    <div class="text-muted" v-if="moving.still_living === false">Do you know who then moved in?</div>
                    <div class="text-muted" v-if="moving.still_living === true">Do you know who will then move in?</div>
                    <div class="mt-3">
                        <btn @click="moving.know_new = true" class="--w350 mb-2" :class="moving.know_new === true ? 'btn-primary' : 'btn-outline-primary'">Yes</btn>
                        <btn @click="moving.know_new = false" class="--w350 mb-2" :class="moving.know_new === false ? 'btn-primary' : 'btn-outline-primary'">No</btn>
                    </div>
                </div>
            </div>

            <div class="box mb-3" v-show-slide="moving.know_new === true">
                <div class="box__body text-center">
                    <div>
                        <div class="text-primary mb-3"><b>Contact details for new occupant: </b></div>
                        <form-input class="mb-2" placeholder="Name" v-model="moving.new_tenent.name"/>
                        <form-input class="mb-2" placeholder="Email"  type="email" v-model="moving.new_tenent.email"/>
                        <form-input class="mb-2" placeholder="Mobile phone"  type="tel" v-model="moving.new_tenent.phone"/>
                    </div>
                </div>
                <div class="box__body text-center">
                    <div>
                        <div class="text-primary mb-3"><b>Is the new occupant:</b></div>
                        <div class="mt-3">
                            <btn @click="moving.new_tenent.type = 'new_tenent'" class="--w250 mb-2" :class="moving.new_tenent.type === 'new_tenent' ? 'btn-primary' : 'btn-outline-primary'">A new tenant</btn>
                            <btn @click="moving.new_tenent.type = 'landlord'" class="--w250 mb-2" :class="moving.new_tenent.type === 'landlord' ? 'btn-primary' : 'btn-outline-primary'">Your landlord</btn>
                            <btn @click="moving.new_tenent.type = 'new_owner'" class="--w250 mb-2" :class="moving.new_tenent.type === 'new_owner' ? 'btn-primary' : 'btn-outline-primary'">A new owner</btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center" v-if="moving.know_new !== null && !this.$v.moving.$invalid">
                <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.moving}" @click.prevent="submitMoving">Submit</button>
                <p class="text-center mt-2" v-if="moving.still_living === false"><small class="text-muted">and go to Meter Reads page</small></p>
            </div>
        </template>

        <template v-if="['Managing agent', 'Letting agent', 'Prime authoritie', 'Leaseholder'].indexOf(selected_property.portalUserRole) > -1">
            <div class="box mb-3">
                <div class="box__body text-center">
                    <div v-if="!bill_tenant">
                        <btn @click="action = 'moving'" class="mb-2" :class="action === 'moving' ? 'btn-primary' : 'btn-outline-primary'">I want to advise of a change of tenancy at {{ selected_property.addressLine1 }}</btn>
                    </div>
                    <div>
                        <btn @click="action = 'selling'" class="mb-2" :class="action === 'selling' ? 'btn-primary' : 'btn-outline-primary'">I am selling my property at {{ selected_property.addressLine1 }}</btn>
                    </div>
                </div>
            </div>

            <template v-if="action === 'selling'">
                <div class="box mb-3" v-show-slide="action !== null">
                    <div class="box__body text-center">
                        <div  style="max-width: 350px" class="mx-auto">
                            <div class="text-muted mb-3">
                                I complete(d) the sale on:
                            </div>
                            <form-date :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="leaseholder_selling.date" :v="$v.leaseholder_selling.date"/>
                        </div>
                    </div>
                </div>
                <div class="box mb-3" v-show-slide="leaseholder_selling.date">
                    <div class="box__body text-center">
                        <div>
                            <div class="text-primary mb-3"><b>Contact details for the new leaseholder: </b></div>
                            <form-input class="mb-2" placeholder="Name" v-model="leaseholder_selling.new_leaseholder.name"/>
                            <form-input class="mb-2" placeholder="Email"  type="email" v-model="leaseholder_selling.new_leaseholder.email"/>
                            <form-input class="mb-2" placeholder="Mobile phone"  type="tel" v-model="leaseholder_selling.new_leaseholder.phone"/>
                        </div>
                    </div>
                </div>

                <div class="text-center" v-if="!this.$v.leaseholder_selling.date.$invalid">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.leaseholder_selling}" @click.prevent="submitSelling">Submit</button>
                </div>
            </template>

            <template v-if="action === 'moving'">

                <div class="box mb-3" v-show-slide="action">
                    <div class="box__body text-center">
                        <div class="text-muted">Do you have a new tenant?</div>
                        <div class="mt-3">
                            <btn @click="leaseholder_moving.have_new_tenent = true" class="--w350 mb-2" :class="leaseholder_moving.have_new_tenent === true ? 'btn-primary' : 'btn-outline-primary'">Yes</btn>
                            <btn @click="leaseholder_moving.have_new_tenent = false" class="--w350 mb-2" :class="leaseholder_moving.have_new_tenent === false ? 'btn-primary' : 'btn-outline-primary'">No</btn>
                        </div>
                    </div>
                </div>

                <div class="box mb-3" v-show-slide="leaseholder_moving.have_new_tenent === false">
                    <div class="box__body text-center">
                        <div class="text-muted"><b class="text-primary"></b>Is a current tenancy ending or has it recently ended?</div>
                        <div class="mt-3">
                            <btn @click="leaseholder_moving.have_past_tenent = true" class="--w350 mb-2" :class="leaseholder_moving.have_past_tenent === true ? 'btn-primary' : 'btn-outline-primary'">Yes</btn>
                            <btn @click="leaseholder_moving.have_past_tenent = false" class="--w350 mb-2" :class="leaseholder_moving.have_past_tenent === false ? 'btn-primary' : 'btn-outline-primary'">No</btn>
                        </div>
                    </div>
                </div>

                <div class="box mb-3" v-show-slide="leaseholder_moving.have_new_tenent === false && leaseholder_moving.have_past_tenent === true">
                    <div class="box__body text-center">
                        <div  style="max-width: 350px" class="mx-auto">
                            <div class="text-primary mb-3"><b>What is / was the end date of this tenancy agreement?</b></div>
                            <form-date :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="leaseholder_moving.date" :v="$v.leaseholder_moving.date"/>
                            <div v-if="date_error_leasehoder" class="text-danger mt-2">{{ date_error_leasehoder }}</div>
                        </div>
                    </div>
                </div>

                <div class="box mb-3" v-show-slide="leaseholder_moving.have_new_tenent === true">
                    <div class="box__body text-center">
                        <div>
                            <div class="text-primary mb-3"><b>Contact details for new tenant: </b></div>
                            <form-input class="mb-2" placeholder="Name" v-model="leaseholder_moving.new_tenent.name"/>
                            <form-input class="mb-2" placeholder="Email"  type="email" v-model="leaseholder_moving.new_tenent.email"/>
                            <form-input class="mb-2" placeholder="Mobile phone"  type="tel" v-model="leaseholder_moving.new_tenent.phone"/>
                        </div>
                    </div>
                    <div class="box__body text-center">
                        <div>
                            <div class="text-primary mb-3"><b>What is the start date of this new tenancy agreement?</b></div>
                            <div class="mt-3">
                                <form-date :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="leaseholder_moving.new_tenent.date"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box mb-3" v-show-slide="leaseholder_moving.new_tenent.date">
                    <div class="box__body text-center">
                        <div class="text-muted"><b class="text-primary"></b>Is a prior tenancy ending or has it recently ended?</div>
                        <div class="mt-3">
                            <btn @click="leaseholder_moving.have_past_tenent = true" class="--w350 mb-2" :class="leaseholder_moving.have_past_tenent === true ? 'btn-primary' : 'btn-outline-primary'">Yes</btn>
                            <btn @click="leaseholder_moving.have_past_tenent = false" class="--w350 mb-2" :class="leaseholder_moving.have_past_tenent === false ? 'btn-primary' : 'btn-outline-primary'">No</btn>
                        </div>
                    </div>
                </div>

                <div class="box mb-3" v-show-slide="leaseholder_moving.have_new_tenent === true && leaseholder_moving.have_past_tenent === true">
                    <div class="box__body text-center">
                        <div  style="max-width: 350px" class="mx-auto">
                            <div class="mb-3 text-primary"><b>What is the end date of this prior tenancy agreement?</b></div>
                            <form-date :time="false" altFormat="d.m.Y" dateFormat="Y-m-d" v-model="leaseholder_moving.date" :v="$v.leaseholder_moving.date"/>
                            <div v-if="date_error_leasehoder" class="text-danger mt-2">{{ date_error_leasehoder }}</div>
                        </div>
                    </div>
                </div>

                <div class="text-center" v-if="!this.$v.leaseholder_moving.$invalid && (leaseholder_moving.have_past_tenent === false || leaseholder_moving.date)">
                    <button type="submit" class="btn btn-outline-secondary --w350" :class="{'loading-btn': loading.leaseholder_moving}" @click.prevent="submitMovingLeaseholder">Submit</button>
                    <p class="text-center mt-2"><small class="text-muted">and go to Meter Reads page</small></p>
                </div>
            </template>
        </template>
    </div>
</template>

<script>

import PropertySelect from '../components/PropertySelect'
import { mapState } from 'vuex'
import PropertyDetails from '../components/PropertyDetails'
import moment from 'moment'
const { required } = require('vuelidate/lib/validators')

export default {
    name: 'Moving',
    components: { PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: false,
                moving: false,
                leaseholder_moving: false,
                leaseholder_selling: false,
            },
            action: '',
            moving: {
                still_living: null,
                date: null,
                know_new: null,
                new_tenent: {
                    name: '',
                    email: '',
                    phone: '',
                    type: '',
                },
            },
            leaseholder_moving: {
                still_living: null,
                date: null,
                have_new_tenent: null,
                have_past_tenent: null,
                new_tenent: {
                    name: '',
                    email: '',
                    phone: '',
                    date: null,
                },
            },
            leaseholder_selling: {
                date: null,
                new_leaseholder: {
                    name: '',
                    email: '',
                    phone: '',
                },
            },
            date_error: '',
            date_error_leasehoder: '',
        }
    },
    watch: {
        'moving.still_living': function (){
            this.validateDate()
        },
        'moving.date': function () {
            this.validateDate()
        },
        'leaseholder_moving.still_living': function (){
            this.validateDateLeaseholder()
        },
        'leaseholder_moving.date': function () {
            this.validateDateLeaseholder()
        }
    },
    validations: {
        moving: {
            still_living: { required },
            date: { required },
            know_new: { required },
        },
        leaseholder_moving: {
            have_new_tenent: { required },
        },
        leaseholder_selling: {
            date: { required }
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
        bill_tenant() {
            return this.selected_property.billTenant == 0 && this.selected_property.billCareOfTenant == 0;
        }
    },
    methods: {
        validateDate () {
            this.date_error = '';
            if(this.moving.still_living === true && moment(this.moving.date).isBefore(moment().startOf('day'))) {
                this.date_error = 'The date you entered is in the past.'
            }
            if(this.moving.still_living === false && moment(this.moving.date).isAfter(moment().startOf('day'))) {
                this.date_error = 'The date you entered is in the future.'
            }
        },
        validateDateLeaseholder () {
            this.date_error_leasehoder = '';
            if(this.leaseholder_moving.still_living === true && moment(this.leaseholder_moving.date).isBefore(moment().startOf('day'))) {
                this.date_error_leasehoder = 'The date you entered is in the past.'
            }
            if(this.leaseholder_moving.still_living === false && moment(this.leaseholder_moving.date).isAfter(moment().startOf('day'))) {
                this.date_error_leasehoder = 'The date you entered is in the future.'
            }
        },
        submitMoving () {
            this.$v.moving.$touch()
            if (this.$v.moving.$invalid === true || this.date_error) {
                return false
            }

            let message = `Are you still living at ${this.selected_property.addressLine1}? ${this.moving.still_living ? 'Yes' : 'No'}
            ${this.moving.still_living ? 'What date does your tenancy agreement end?' : 'What date did your tenancy agreement end?'} ${this.moving.date}
            Do you know who then moved in? ${this.moving.know_new ? 'Yes' : 'No'}
            `
            if (this.moving.know_new) {
                message += `Contact details for new occupant:
                Name: ${this.moving.new_tenent.name}
                Email: ${this.moving.new_tenent.email}
                Mobile phone: ${this.moving.new_tenent.phone}
                The new ocupant is: `;
                if (this.moving.new_tenent.type === 'new_tenent') {
                    message += 'A new tenant'
                }
                if (this.moving.new_tenent.type === 'landlord') {
                    message += 'Your landlord'
                }
                if (this.moving.new_tenent.type === 'new_owner') {
                    message += 'A new owner'
                }
            }
            this.loading.moving = true
            this.$http.get('/userpostdata', {
                noMessage: true,
                params :{
                    propertyid: this.selected_property.billingPropertyID,
                    postType: 'Address change',
                    message: message
                }})
                .then(() => {
                    this.loading.moving = false
                    if(this.moving.still_living === false) {
                        this.$toastr.s("Please submit a meter read for the day you vacated", "Thank you!")
                        this.$router.push({name: 'submit-readings'})
                    }else{
                        this.$toastr.s("Don’t forget to submit a meter read on the day you vacate", "Thank you!")
                        this.action = '';
                        this.moving = {
                            still_living: null,
                            date: null,
                            know_new: null,
                            new_tenent: {
                                name: '',
                                email: '',
                                phone: '',
                                type: '',
                            },
                        }
                        this.$v.$reset()
                    }
                })
                .catch(() => {
                    this.loading.moving = false
                })
        },
        submitMovingLeaseholder () {
            this.$v.leaseholder_moving.$touch()
            if (this.$v.leaseholder_moving.$invalid === true || this.date_error_leasehoder) {
                return false
            }

            let message = `I want to advise of a change of tenancy at ${this.selected_property.addressLine1}
Do you have a new tenant? ${this.leaseholder_moving.have_new_tenent ? 'Yes' : 'No'}
`

            if (this.leaseholder_moving.have_new_tenent === false) {
                message += `Is a current tenancy ending or has it recently ended? ${this.leaseholder_moving.have_past_tenent ? 'Yes' : 'No'}
`
                if (this.leaseholder_moving.have_past_tenent === true) {
                    message += `What is / was the end date of this tenancy agreement? ${this.leaseholder_moving.date}
`
                }
            }else{
                message += `Contact details for new tenant:
Name: ${this.leaseholder_moving.new_tenent.name}
Email: ${this.leaseholder_moving.new_tenent.email}
Mobile phone: ${this.leaseholder_moving.new_tenent.phone}
Start date: ${this.leaseholder_moving.new_tenent.date}
Is a prior tenancy ending or has it recently ended? ${this.leaseholder_moving.have_past_tenent ? 'Yes' : 'No'}
`;
                if (this.leaseholder_moving.have_past_tenent === true) {
                    message += `What is the end date of this prior tenancy agreement? ${this.leaseholder_moving.new_tenent.date}
`
                }

            }
            this.loading.leaseholder_moving = true
            this.$http.get('/userpostdata', {
                noMessage: true,
                params :{
                    propertyid: this.selected_property.billingPropertyID,
                    postType: 'Address change',
                    message: message
                }})
                .then(() => {
                    this.loading.leaseholder_moving = false
                    // if(this.leaseholder_moving.still_living === false) {
                        this.$toastr.s("Please submit a meter read for the day they vacated", "Thank you!")
                        this.$router.push({name: 'submit-readings'})
                    // }else{
                    //     this.$toastr.s("Don't forget to submit a meter read for the day they vacate.", "Thank you!")
                    //     this.action = '';
                    //     this.leaseholder_moving = {
                    //         still_living: null,
                    //         date: null,
                    //         have_new_tenent: null,
                    //         new_tenent: {
                    //             name: '',
                    //             email: '',
                    //             phone: '',
                    //             date: null,
                    //         },
                    //     }
                    //     this.$v.$reset()
                    // }
                })
                .catch(() => {
                    this.loading.leaseholder_moving = false
                })
        },
        submitSelling () {
            this.$v.leaseholder_selling.$touch()
            if (this.$v.leaseholder_selling.$invalid === true || this.date_error_leasehoder) {
                return false
            }

            let message = `I'am selling my property at ${this.selected_property.addressLine1}
            I complete(d) the sale on ${this.leaseholder_selling.date}
            Contact details for the new leaseholder:
            Name: ${this.leaseholder_selling.new_leaseholder.name}
            Email: ${this.leaseholder_selling.new_leaseholder.email}
            Mobile phone: ${this.leaseholder_selling.new_leaseholder.phone}`;

            this.loading.leaseholder_selling = true
            this.$http.get('/userpostdata', {
                noMessage: true,
                params :{
                    propertyid: this.selected_property.billingPropertyID,
                    postType: 'Address change',
                    message: message
                }})
                .then(() => {
                    this.loading.leaseholder_selling = false
                    this.$toastr.s("Don't forget to submit a meter read for the change date", "Thank you!")
                    this.action = '';
                    this.leaseholder_selling = {
                        date: null,
                        new_leaseholder: {
                            name: '',
                            email: '',
                            phone: '',
                        },
                    }
                    this.$v.$reset()
                })
                .catch(() => {
                    this.loading.leaseholder_selling = false
                })
        },
    }
}
</script>
