<template>
    <div class="container" style="max-width: 900px;">
        <div class="page-subtitle">Hi {{ user.name }}</div>
        <p class="page-description">Submit Meter Reads</p>

        <property-details class="mb-3"/>

        <div class="box mb-3">
            <div class="box__body reading-info">
                <h3>What to do</h3>
                <br>
                <ol>
                    <li>Please enter your read and the date it was taken in the boxes below and then submit</li>
                    <li>If the previous read shown is actual (A) or manual (M) or customer (C) then your read will normally be higher</li>
                    <li>If the previous read is estimated (E) then your read maybe above or below</li>
                    <li>It is a good idea to take a picture of the meter / read in case of any query</li>
                    <li>Thank you!</li>
                </ol>
            </div>
        </div>
        <tabs class="reading-tabs">
            <tab v-if="hasSupplyType('Gas')" tabclass="tab-Gas" class="tab-Gas" icon="flaticon-flame" name="Gas">
                <div class="table-responsive">
                    <table class="table mb-4">
                        <thead>
                        <tr class="tab-title-mobile">
                            <th colspan="3">Gas</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Reading</th>
                            <th>Meter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="read in reads.Gas.reads">
                            <td>{{ read.readDate | moment('DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss') }}</td>
                            <td><span v-tooltip="read.readType">{{ read.readValue }} ({{ read.readTypeAbbreviation }})</span></td>
                            <td>{{ read.meterNumber }}</td>
                        </tr>
                        <tr>
                            <td>
                                <form-input type="date" size="sm" v-model="reads.Gas.date" :v="$v.reads.Gas.date"/>
                            </td>
                            <td>
                                <form-input type="number" size="sm" v-model="reads.Gas.reading" :v="$v.reads.Gas.reading" decimals="0"/>
                            </td>
                            <td>
                                <form-btn size="sm" class="btn-outline-secondary btn-block" :loading="loading.Gas" @click="submitReading('Gas')">Submit</form-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </tab>
            <tab v-if="hasSupplyType('Heat Network')" tabclass="tab-Heat" class="tab-Heat" icon="flaticon-flame" name="Heat Network">
                <div class="table-responsive">
                    <table class="table mb-4">
                        <thead>
                        <tr class="tab-title-mobile">
                            <th colspan="3">Heat Network</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Reading</th>
                            <th>Meter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="read in reads['Heat Network'].reads">
                            <td>{{ read.readDate | moment('DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss') }}</td>
                            <td><span v-tooltip="read.readType">{{ read.readValue }} ({{ read.readTypeAbbreviation }})</span></td>
                            <td>{{ read.meterNumber }}</td>
                        </tr>
                        <tr>
                            <td>
                                <form-input type="date" size="sm" v-model="reads['Heat Network'].date" :v="$v.reads['Heat Network'].date"/>
                            </td>
                            <td>
                                <form-input type="number" size="sm" v-model="reads['Heat Network'].reading" :v="$v.reads['Heat Network'].reading" decimals="0"/>
                            </td>
                            <td>
                                <form-btn size="sm" class="btn-outline-secondary btn-block" :loading="loading['Heat Network']" @click="submitReading('Heat Network')">Submit</form-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </tab>
            <tab v-if="hasSupplyType('Electricity')" tabclass="tab-Electricity" class="tab-Electricity" icon="flaticon-light" name="Electricity">
                <div class="table-responsive">
                    <table class="table mb-4">
                        <thead>
                        <tr class="tab-title-mobile">
                            <th colspan="3">Electricity</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Reading</th>
                            <th>Meter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="read in reads.Electricity.reads">
                            <td>{{ read.readDate | moment('DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss') }}</td>
                            <td><span v-tooltip="read.readType">{{ read.readValue }} ({{ read.readTypeAbbreviation }})</span></td>
                            <td>{{ read.meterNumber }}</td>
                        </tr>
                        <tr>
                            <td>
                                <form-input type="date" size="sm" v-model="reads.Electricity.date" :v="$v.reads.Electricity.date"/>
                            </td>
                            <td>
                                <form-input type="number" size="sm" v-model="reads.Electricity.reading" :v="$v.reads.Electricity.reading" decimals="0"/>
                            </td>
                            <td>
                                <form-btn size="sm" class="btn-outline-secondary btn-block" :loading="loading.Electricity" @click="submitReading('Electricity')">Submit</form-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </tab>
            <tab v-if="hasSupplyType('Water')" tabclass="tab-Water" class="tab-Water" icon="flaticon-water" name="Water">
                <div class="table-responsive">
                    <table class="table mb-4">
                        <thead>
                        <tr class="tab-title-mobile">
                            <th colspan="3">Water</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>Reading</th>
                            <th>Meter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="read in reads.Water.reads">
                            <td>{{ read.readDate | moment('DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss') }}</td>
                            <td><span v-tooltip="read.readType">{{ read.readValue }} ({{ read.readTypeAbbreviation }})</span></td>
                            <td>{{ read.meterNumber }}</td>
                        </tr>
                        <tr>
                            <td>
                                <form-input type="date" size="sm" v-model="reads.Water.date" :v="$v.reads.Water.date"/>
                            </td>
                            <td>
                                <form-input type="number" size="sm" v-model="reads.Water.reading" :v="$v.reads.Water.reading" decimals="0"/>
                            </td>
                            <td>
                                <form-btn  size="sm" class="btn-outline-secondary btn-block" :loading="loading.Water" @click="submitReading('Water')">Submit</form-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </tab>
        </tabs>

    </div>
</template>

<script>

import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'
import moment from 'moment'
import { mapState } from 'vuex'
import Tabs from '../components/Tabs'
import Tab from '../components/Tab'
const { required } = require('vuelidate/lib/validators')

export default {
    name: 'SubmitReadings',
    components: { Tab, Tabs, PropertyDetails, PropertySelect },
    data () {
        return {
            loading: {
                data: false,
                Gas: false,
                Electricity: false,
                Water: false,
                'Heat Network': false,
            },
            reads: {
                Gas: {
                    date: null,
                    reads: [],
                    reading: '',
                },
                'Heat Network': {
                    date: null,
                    reads: [],
                    reading: '',
                },
                Electricity: {
                    date: null,
                    reads: [],
                    reading: '',
                },
                Water: {
                    date: null,
                    reads: [],
                    reading: '',
                },
            }
        }
    },
    validations: {
        reads: {
            Gas: {
                date: { required },
                reading: { required },
            },
            'Heat Network': {
                date: { required },
                reading: { required },
            },
            Water: {
                date: { required },
                reading: { required },
            },
            Electricity: {
                date: { required },
                reading: { required },
            },
        }
    },
    created () {
        this.reads.Gas.date = moment().format('YYYY-MM-DD');
        this.reads['Heat Network'].date = moment().format('YYYY-MM-DD');
        this.reads.Water.date = moment().format('YYYY-MM-DD');
        this.reads.Electricity.date = moment().format('YYYY-MM-DD');
    },
    mounted () {
        this.getAllReads()
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            selected_property: (state) => state.selected_property,
        }),
    },
    watch: {
        selected_property () {
            this.getAllReads()
        }
    },
    methods: {
        getAllReads () {
            this.selected_property.supplyTypeList.forEach((supplyType) => {
                this.getReads(supplyType.supplyType)
            })
        },
        getReads (supplyType) {
            if (!this.selected_property) {
                return
            }
            let filters = {
                pagenumber: 0,
                rowsperpage: 2,
                propertyid: this.selected_property.billingPropertyID,
                supplytype: supplyType,
            }

            this.$http.get('/userreads', { params: filters })
                .then((res) => {
                    this.reads[supplyType].reads = res.body.readList
                    this.loading.data = false
                })
                .catch(() => {
                    this.loading.data = false
                })
        },
        hasSupplyType (type) {
            return this.selected_property.supplyTypeList.some((supplyType) => {
                return supplyType.supplyType === type
            })
        },
        submitReading (type) {
            this.$v.reads[type].$touch()
            if (this.$v.reads[type].$invalid === true) {
                return false
            }
            this.loading[type] = true
            this.$http.get('/userpostdata', {params :{
                postType: 'Read',
                propertyid: this.selected_property.billingPropertyID,
                supplytype: type,
                readvalue: this.reads[type].reading,
                postdate: this.reads[type].date,
            }})
                .then(() => {
                    this.getReads(type)
                    this.reads[type].reading = '';
                    this.reads[type].date = '';
                    this.$v.reads[type].$reset()
                    this.loading[type] = false;
                    window.scrollTo({top: 0, behavior: 'smooth'});
                })
                .catch(() => {
                    this.loading[type] = false
                })
        }
    }
}
</script>
