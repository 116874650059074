<template functional>
    <apexchart id="chart" :height="270" type="bar"
               :options="{
                chart: {
                    toolbar: {
                        show: false,
                    },
                },
                colors: ['#fbbe24', 'rgb(69,250,148)'],
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: 'category',
                    categories: props.categories,
                    labels: {
                        show: true,
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
                            return `${props.series[seriesIndex].data[dataPointIndex].label}: ${val} ${props.units}`
                        },
                        title: {
                            formatter: function (seriesName) {
                                return  ''
                            },
                        }
                    }
                }
            }"
               :series="props.series"></apexchart>
</template>

<style>
#chart svg {
    overflow: visible;
}
</style>

<script>
export default {
    name: 'LineChart',
    data() {
        return {
            chartOptions: {}
        }
    },
    methods: {
        // formater(val, { series, seriesIndex, dataPointIndex, w }) {
        //         // return props.series[seriesIndex][dataPointIndex]
        //         return `${val} kWh`
        // }
    }
}
</script>
