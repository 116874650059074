import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// import bugsnagClient from '../bugsnag';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        meta: {
            title: null,
            description: '',
            breadcrumbs: [],
        },
        logged: false,
        userLoading: false,
        userLoaded: false,
        Loaded: false,
        user: {
            id: null,
            name: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            userType: '',
        },
        properties: [],
        selected_property: null,
        sites: [],
        selected_site: null,
        force_new_pass: false,
    },
    mutations: {
        setMeta: (state, payload) => {
            state.meta.title = payload.title ? payload.title : null
            state.meta.description = payload.description ? payload.description : ''
            state.meta.breadcrumbs = payload.breadcrumbs ? payload.breadcrumbs : []
        },
        setUserLoading: (state, value) => {
            state.userLoading = value
        },
        setUserLoaded: (state) => {
            state.userLoaded = true
        },
        setUser: (state, user) => {
            state.logged = true
            state.user.id = user.userID
            state.user.email = user.userEmail
            state.user.phone = user.userPhone
            state.user.name = user.userName
            state.user.firstname = user.userForenames
            state.user.lastname = user.userSurname
            state.user.userType = user.userType ? user.userType : 'Property'
            state.userLoaded = true
            // bugsnagClient.user = state.user;
        },
        setProperties: (state, properties) => {
            state.properties = properties
        },
        selectProperty: (state, property) => {
            state.selected_property = property
        },
        setSites: (state, sites) => {
            state.sites = sites
        },
        selectSite: (state, site) => {
            if(state.selected_site != site) {
                state.selected_property = null;
                state.properties = [];
            }
            state.selected_site = site
        },
        logout: (state) => {
            state.logged = false;
            state.properties = [];
            state.sites = [];
            state.selected_property = null;
            state.selected_site = null;
            state.force_new_pass = false;
            state.user = {
                id: null,
                name: '',
                email: '',
                phone: '',
                userType: '',
            }
        },
        setForceNewPass(state, payload) {
            state.force_new_pass = payload
        },
    },
    actions: {
        logout (context, vm){
            vm.$acl.change('public');
            context.commit('logout', this)
            vm.$router.push({ name: 'login' })
            vm.$auth.destroySession()
        },
    },
    getters: {
        hasMoreProperties: state => {
            return state.properties && state.properties.length > 1;
        },
        hasMoreSites: state => {
            return state.sites && state.sites.length > 1;
        }
    },
    plugins: [new VuexPersistence().plugin]
})
