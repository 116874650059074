<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass]">
        <textarea :rows="props.rows ? props.rows : 5" :type="props.type ? props.type: 'text'" class="form-control" :placeholder="props.placeholder"
                  :class="{
                        'form-control-lg': props.size === 'lg',
                        'form-control-sm': props.size === 'sm',
                        'form-control-xs': props.size === 'xs',
                         'is-invalid' : props.v ? props.v.$error : null
                    }"
                  :value="props.value"
                  :disabled="props.disabled"
                  :readonly="props.readonly"
                  v-on:input="($event) => {
                      listeners.input && listeners.input($event.target.value);
                      if(props.v){
                           props.v.$touch();
                      }
                  }"
        />
    </form-group>
</template>

<script>
export default {
  name: 'FormTextarea',
};
</script>
