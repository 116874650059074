import Vue from 'vue';
import { AclInstaller, AclCreate, AclRule } from 'vue-acl';
import router from './router';

Vue.use(AclInstaller);
export default new AclCreate({
  initial: localStorage.getItem('role') ? localStorage.getItem('role') : 'public',
  notfound: {
    path: '/login',
    forwardQueryParams: false,
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isPublic: new AclRule('public').or('logged').generate(),
    isLogged: new AclRule('logged').generate(),
  },
  middleware: async () => {
    // console.log('middleware');
    // acl.change('logged');
  },
});
