<template functional>
    <form-group :label="props.label" :help="props.help" :class="[data.staticClass, props.v && props.v.$error ? 'has-error' : null]">
        <div class="input-group">
            <div class="input-group-prepend" v-if="!!$slots.prepend">
                <span class="input-group-text"><slot name="prepend"></slot></span>
            </div>
            <flat-pickr class="form-control" :placeholder="props.placeholder"
                    :class="{
                        'form-control-lg': props.size === 'lg',
                        'form-control-sm': props.size === 'sm',
                        'form-control-xs': props.size === 'xs',
                    }"
                    :value="props.value"
                    :disabled="props.disabled"
                    v-on:input="(value) => {
                        listeners.input && listeners.input(value);
                        if(props.v){
                            props.v.$touch();
                        }
                    }"
                    :config="{
                        enableTime: props.time,
                        allowInput: true,
                        time_24hr: true,
                        altFormat: props.altFormat ? props.altFormat : 'd.m.Y H:i',
                        dateFormat: props.dateFormat ? props.dateFormat :  'Y-m-d H:i:S',
                        altInput: true
                    }"
                    :required="props.required"
                    >
            </flat-pickr>
            <div class="input-group-prepend" v-if="!!slots.append">
                <span class="input-group-text"><slot name="append"></slot></span>
            </div>
        </div>
    </form-group>
</template>

<script>
export default {
  name: 'FormDate',
};
</script>
