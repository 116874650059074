<template>
    <div class="container" style="max-width: 900px;">
        <h1 class="page-title">FAQ</h1>
        <template v-if="selected_property">
            <f-a-q-item :key="index" v-for="(question, index) in selected_property.faqList" :question="question"/>
        </template>
        <div class="empty-text" v-else>
            Please select a property
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import FAQItem from '@/views/FAQItem'

export default {
    name: 'FAQ',
    components: { FAQItem },
    data () {
        return {
            loading: {
                data: false
            },
        }
    },
    computed: {
        ...mapState({
            selected_property: (state) => state.selected_property,
        }),
    },
}
</script>
