<template functional>
    <button :class="[data.staticClass, data.class, {
                        'btn-lg': props.size === 'lg',
                        'btn-sm': props.size === 'sm',
                        'btn-xs': props.size === 'xs',
                    }, {'loading-btn': props.loading}]"
            class="btn"
            :disabled="props.disabled || props.loading"
            :readonly="props.readonly"
            :name="props.name"
            :value="props.value"
            v-on:click="listeners.click && listeners.click()"><slot></slot></button>
</template>

<script>
export default {
  name: 'Btn',
};

</script>
