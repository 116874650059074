<template>
    <div class="container" style="max-width: 900px;">

        <div class="box my-4">
            <div class="box__body">
                <div><b class="text-primary">{{ user.name }} </b></div>
                <div>{{ user.email }}</div>
                <div>{{ user.phone }}</div>
            </div>
        </div>

        <div class="box mb-4">
            <div class="box__header">
                <div>You must set a stronger password</div>
            </div>
            <div class="box__body">
                <div class="widget-body">
                    <form-input class="mb-3" v-model="password.oldpw" :v="$v.password.oldpw" type="password" placeholder="Old password"/>
                    <form-input class="mb-3" v-model="password.newpw" :v="$v.password.newpw" type="password" placeholder="New password"/>
                    <form-input v-model="password.confirm_newpw" :v="$v.password.confirm_newpw" type="password" placeholder="Confirm new password"/>
                    <ul class="small mt-2" v-show-slide="$v.password.newpw.$dirty">
                        <li :class="!$v.password.newpw.minLength ? 'text-danger' : 'text-success'">At least 12 characters</li>
                        <li :class="!$v.password.newpw.lowercase_character ? 'text-danger' : 'text-success'">At least 1 lowercase letter</li>
                        <li :class="!$v.password.newpw.upercase_character ? 'text-danger' : 'text-success'">At least 1 uppercase letter</li>
                        <li :class="!$v.password.newpw.number_character ? 'text-danger' : 'text-success'">At least 1 number</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="text-center mb-4">
            <btn class="btn-outline-secondary --w350" :loading="loading.password" @click="changePassword">Set Password</btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import CryptoJS from 'crypto-js';

const { required, sameAs, email, minLength, helpers } = require('vuelidate/lib/validators')
const lowercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[a-z]).*$/)
const upercase_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[A-Z]).*$/)
const number_character = helpers.regex('alpha', /^.*(?=.{3,})(?=.*[0-9])(?=.*[\d\x]).*$/)

export default {
    name: 'NewPassword',
    data () {
        return {
            loading: {
                password: false,
            },
            password: {
                oldpw: '',
                newpw: '',
                confirm_newpw: '',
            },
        }
    },
    validations: {
        password: {
            oldpw: { required },
            newpw: { required, minLength: minLength(12), lowercase_character, upercase_character, number_character },
            confirm_newpw: { sameAs: sameAs('newpw') },
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            sites: (state) => state.sites,
            properties: (state) => state.properties,
        }),
    },
    methods: {
        changePassword () {
            this.$v.password.$touch()
            if (this.$v.password.$invalid === true) {
                return false
            }
            this.loading.password = true
            let pwed = this.randomStr(4) + this.password.newpw;
            pwed = CryptoJS.enc.Utf8.parse(pwed);
            pwed = CryptoJS.enc.Base64.stringify(pwed);
            let oldpwed = this.randomStr(4) + this.password.oldpw;
            oldpwed = CryptoJS.enc.Utf8.parse(oldpwed);
            oldpwed = CryptoJS.enc.Base64.stringify(oldpwed);
            this.$http.post('/usersetpassword', {}, {
                // params: {
                //     email: this.user.email,
                //     oldpw: this.password.oldpw,
                //     newpw: this.password.newpw
                // },
                headers: {
                    Authorization: pwed,
                    PreAuthorization: oldpwed,
                    Email: this.user.email
                }
            })
                .then((res) => {
                    if (!res.body.errorNumber) {
                        this.$store.commit('setForceNewPass', false)
                        this.password.oldpw = ''
                        this.password.newpw = ''
                        this.password.confirm_newpw = ''
                        this.$v.password.$reset()
                        this.redirectUser();
                    }
                    this.loading.password = false
                }).catch(() => {
                    this.loading.password = false
                })
        },
        redirectUser() {
            if (this.user.userType === 'Site') {
                if(this.sites.length > 1){
                    this.$router.push({ name: 'site-select' })
                }else{
                    this.$router.push({ name: 'property-select' })
                }
            }else{
                if(this.properties.length > 1){
                    this.$router.push({ name: 'property-select' })
                }else{
                    this.$router.push({ name: 'dashboard' })
                }
            }
        },
    }
}
</script>
