<template>
    <div class="container" style="max-width: 900px;">
        <h1 class="page-title">{{ type | ucfirst }} charges</h1>

        <property-select class="mb-3"/>

        <div class="balance-box">
            <div class="balance-box__header">
                <div class="balance-box__text">Your {{ type }} meter</div>
                <div class="balance-box__amount">Serial: I98G11204</div>
            </div>
            <div class="balance-box__body text-center">
                <p class="text-primary">Last meter reading (estimated or actual)</p>
                <p style="font-size: 36px">44846.XX</p>
                <div class="text-primary">on 2nd Oct. 2020</div>
            </div>
        </div>

        <property-details class="mb-3"/>

        <charge-box class="mb-3"/>

    </div>
</template>

<script>
import { mapState } from 'vuex'
import ChargeBox from '../components/ChargeBox'
import PropertySelect from '../components/PropertySelect'
import PropertyDetails from '../components/PropertyDetails'

export default {
    name: 'Invoices',
    components: { PropertyDetails, PropertySelect, ChargeBox },
    data () {
        return {
            loading: {
                data: false
            },
            type: '',
        }
    },
    created () {
        this.type = this.$route.meta.type;
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    methods: {
        getInvoices () {
            this.$v.user.$touch()
            if (this.$v.user.$invalid === true) {
                return false
            }
            this.loading.save = true
            this.$http.post('/api/v2/admin/account/edit', this.user)
                .then(() => {
                    this.loading.save = false
                    this.getUserData()
                })
                .catch(() => {
                    this.loading.save = false
                })
        },
    }
}
</script>
